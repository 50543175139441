import React from "react";
import { TextInput, Create, SimpleForm, useAuthenticated, required } from "react-admin";
import SaveOnlyToolbar from "../common/SaveOnlyToolbar";
import { validateUrl } from "../common/validators";
import globalStyles from "../common/globalStyles";

const ApplicationCreate = (props: any) => {
  const styles = globalStyles();
  useAuthenticated();
  return (
    <div>
      <Create {...props} successMessage="Application Saved">
        <SimpleForm toolbar={<SaveOnlyToolbar />} className={styles.simpleForm}>
          <TextInput source="name" validate={[required()]} fullWidth />
          <TextInput source="key" parse={(v: string) => v.toLowerCase()} validate={[required()]} fullWidth />
          <TextInput
            source="url"
            helperText="http(s)://yourdomain.com"
            validate={[required(), validateUrl]}
            fullWidth
          />
          <TextInput
            label="Admin URL"
            helperText="http(s)://yourdomain.com"
            source="adminUrl"
            validate={[required(), validateUrl]}
            fullWidth
          />
        </SimpleForm>
      </Create>
      <br />
    </div>
  );
};

export default ApplicationCreate;
