import React from "react";
import { parse } from "query-string";
import {
  TextInput,
  useAuthenticated,
  Create,
  BooleanInput,
  ReferenceInput,
  AutocompleteInput,
  SimpleForm,
  required
} from "react-admin";
import SaveOnlyToolbar from "../common/SaveOnlyToolbar";
import globalStyles from "../common/globalStyles";

const redirect = (basePath: string, id: string, data: any) =>
      `/applications/${data.applicationUid}/1`;

const ModuleCreate = (props: any) => {
  const styles = globalStyles();
  const { location: { search } } = props;
  let postDefaultValue = {
    defaultPermission: true,
  };

  if (search) {
    postDefaultValue = {...postDefaultValue, ...parse(search)};
  }

  useAuthenticated();
  return (
    <div>
      <Create {...props} successMessage="Module Saved">
        <SimpleForm
          toolbar={<SaveOnlyToolbar />}
          initialValues={postDefaultValue}
          redirect={redirect}
          className={styles.simpleForm}
        >
          <TextInput label="name" source="name" validate={[required()]} fullWidth/>
          <TextInput label="key" source="key" validate={[required()]} fullWidth/>
          <TextInput source="description" multiline fullWidth/>
          <ReferenceInput
            source="applicationUid"
            reference="applications"
            label="Application"
            validate={[required()]}
            fullWidth
          >
            <AutocompleteInput optionText="name" />
          </ReferenceInput>
          <BooleanInput
            label="Default Permission"
            source="defaultPermission"
          />
        </SimpleForm>
      </Create>
      <br />
    </div>
  );
};

export default ModuleCreate;
