import React from "react";
import { Admin, Resource /*, ListGuesser, EditGuesser*/ } from "react-admin";
import applications from "./applications";
import organizations from "./organizations";
import organizationsApplications from "./organizations-applications";
import modules from "./modules";
import users from "./users";
import usersApplications from "./users-applications";
import dashboard from "./dashboard";
import authProvider from "./providers/authProvider";
import dataProvider from "./providers/umsDataProvider";
import i18nProvider from "./providers/i18nProvider";
import { Login, Layout } from "./layout";
import { Permissions } from "./types";

const App = () => (
  <Admin
    layout={Layout}
    loginPage={Login}
    dashboard={dashboard}
    authProvider={authProvider}
    dataProvider={dataProvider}
    i18nProvider={i18nProvider}
  >
    {(permissions: Permissions) => [
      <Resource
        name="users"
        edit={permissions.readOnly ? null : users.edit}
        show={permissions.readOnly ? users.show : null}
        create={permissions.readOnly ? null : users.create}
        list={users.list}
        filter={{ simple: true }} />,
      <Resource
        name="users-applications"
        options={{ label: "Users Applications" }}
        edit={permissions.readOnly ? null : usersApplications.edit}
        show={permissions.readOnly ? usersApplications.show : null} />,
      <Resource name="users-organizations" options={{ label: "Users Organizations" }} />,
      <Resource
        name="applications"
        edit={permissions.readOnly ? null : applications.edit}
        show={permissions.readOnly ? applications.show : null}
        create={permissions.readOnly ? null : applications.create}
        list={applications.list}
        filter={{ simple: true }} />,
      <Resource
        name="organizations"
        list={permissions.readOrganization ? organizations.list : null}
        edit={permissions.changeOrganization ? organizations.edit : null}
        show={organizations.show}
        icon={organizations.icon}
        filter={{ simple: true }}
      />,
      permissions.readOrganization ? (
        <Resource
          name="organizations-applications"
          options={{ label: "Organizations Applications" }}
          {...organizationsApplications}
        />
      ) : null,
      <Resource
        name="modules"
        edit={permissions.readOnly ? null : modules.edit}
        show={permissions.readOnly ? modules.show : null}
        list={modules.list}
        create={permissions.readOnly ? null : modules.create} />
    ]}
  </Admin>
);

export default App;
