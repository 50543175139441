import React from "react";
import {
  Show,
  TextInput,
  Datagrid,
  TextField,
  ReferenceManyField,
  Pagination,
  TabbedForm,
  FormTab,
  required,
  email,
  useAuthenticated,
  useQuery,
  TopToolbar,
  ListButton,
} from "react-admin";

import AttributeGrid from "../common/AttributeGrid";
import ApplicationManager from "../common/ApplicationManager";
import UserStatusSelect from "./UserStatusSelect";
import UserTypeSelect from "./UserTypeSelect";
import RoleManager from "./RoleManager";
import LoginHistoryTable from "./LoginHistoryTable";
import ImpersonationHistoryTable from "./ImpersonationHistoryTable";
import globalStyles from "../common/globalStyles";

const UserEditTitle = ({ record }: any) => {
  return <span>{record ? `${record.userName}` : ""}</span>;
};

const UserEditActions = ({ basePath, data, resource }: any) => {
  return (
    <TopToolbar>
      <ListButton basePath={basePath} record={data} />
    </TopToolbar>
  );
};

const UserShow = (props: any) => {
  const styles = globalStyles();
  const readOnly = props?.permissions?.readOnly && true;
  useAuthenticated();

  const { data, loading, error } = useQuery({
    type: "getList",
    resource: `users`,
    payload: {
      filter: { type: "Role", status: "Active" },
      pagination: { page: 1, perPage: 100 }
    }
  });
  if (loading) {
    return <p>Loading</p>;
  }
  if (error) {
    return <p>ERROR</p>;
  }

  const activeRoles = data.map((u: any) => ({ id: u.uid, name: u.userName }));

  return (
    <div>
      <Show
        {...props}
        title={<UserEditTitle />}
        actions={<UserEditActions />}
      >
        <TabbedForm toolbar={null}>
          <FormTab label="Summary">
            <TextInput disabled label="UID" source="uid" className={styles.input} />
            <TextInput disabled label="Username" source="userName" validate={[required()]} className={styles.input} />
            <TextInput disabled label="First Name" source="firstName" validate={[required()]} className={styles.input} />
            <TextInput disabled label="Last Name" source="lastName" validate={[required()]} className={styles.input} />
            <TextInput
              disabled
              label="Email Address"
              source="email"
              type="email"
              validate={[required(), email()]}
              className={styles.input}
            />
            <UserStatusSelect disabled source="status" className={styles.input} />
            <UserTypeSelect disabled source="type" className={styles.input} />
            <RoleManager disabled label="Roles" source="role_ids" choices={activeRoles} />
          </FormTab>
          <FormTab label="Attributes">
            <AttributeGrid {...props} readOnly={readOnly} />
          </FormTab>
          <FormTab label="Applications">
            <ApplicationManager readOnly={readOnly}/>
          </FormTab>
          <FormTab label="Organizations">
            <ReferenceManyField
              fullWidth
              pagination={<Pagination />}
              reference="users-organizations"
              target="userUid"
              sort={{ field: "organization.name", order: "ASC" }}
              addLabel={false}
            >
              <Datagrid>
                <TextField label="Name" source="organization.name" />
                <TextField label="Type" source="organization.type" />
              </Datagrid>
            </ReferenceManyField>
          </FormTab>
          <FormTab label="Login History">
            <LoginHistoryTable {...props} />
          </FormTab>
          <FormTab label="Impersonation History">
            <ImpersonationHistoryTable {...props} />
          </FormTab>
        </TabbedForm>
      </Show>
    </div>
  );
};

export default UserShow;
