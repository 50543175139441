import dataProvider from "./umsDataProvider";
import decode from "jwt-decode";
import { Permissions } from "../types";
import { BASE_URL, JSON_HEADERS } from "../constants";

const getLocalPermissions = () => {
  const token = getDecodedToken();
  const modulePermissions = getPermissionsFromString(localStorage.getItem("permissions") ?? "");
  return {
    ...modulePermissions,
    readOnly: !token?.isUmsClientAdmin
  };
};

const getPermissionsFromString = (stringified: string): Permissions => {
  let parsed: Permissions = {
    readOnly: true,
    readOrganization: false,
    changeOrganization: false,
    createOrganization: false
  };

  try {
    parsed = JSON.parse(stringified);
  } catch (err) {
    parsed = {
      readOnly: true,
      readOrganization: false,
      changeOrganization: false,
      createOrganization: false
    };
  }
  return parsed;
};

const permissionsFromModules = (data: any): Permissions => {
  const keyPrefix = "ums-client.ums-ui-service";
  const perms: Permissions = {
    readOnly: true,
    readOrganization:
      (data || []).find((m: any) => m.module.key === `${keyPrefix}.read-organization` && m.permissionOverwrite) !==
      undefined,
    changeOrganization:
      (data || []).find((m: any) => m.module.key === `${keyPrefix}.change-organization` && m.permissionOverwrite) !==
      undefined,
    createOrganization:
      (data || []).find((m: any) => m.module.key === `${keyPrefix}.create-organization` && m.permissionOverwrite) !==
      undefined
  };
  return perms;
};

const clearAuthStorage = () => {
  const keys = ["token", "permissions"];
  keys.forEach(k => localStorage.removeItem(k));
};

type UmsToken = {
  uid: string;
  firstName: string;
  lastName: string;
  userName?: string;
  email: string;
  type: string;
  status: string;
  sessionUid: string;
  iat: number;
  exp: number;
  iss: string;
  jti: string;
  impersonatingUser?: {
    uid: string;
    userName: string;
    firstName: string;
    lastName: string;
  };
  umsClientAdmin?: {
    roleName?: string;
    userName?: string;
    uid: string;
  };
  isUmsClientAdmin: boolean;
};

const getDecodedToken = (token: string = localStorage.getItem("token") || "") => {
  try {
    let decoded = decode(token) as UmsToken;
    decoded.isUmsClientAdmin = decoded.umsClientAdmin !== undefined;
    return decoded;
  } catch (err) {
    return null;
  }
};

const getTokenAndPermissions = async (response: Response) => {
  return response
    .json()
    .then(({ sessionToken }) => {
      const token = getDecodedToken(sessionToken);
      let userUid = token?.uid;

      if (!token) {
        return Promise.reject("User does not have access to the UMS Client application");
      }

      if ((token.type || '').toLowerCase() !== 'ldapuser') {
        return Promise.reject("Only ldap users can access the UMS Client Application");
      }

      if (!token.isUmsClientAdmin) {
        userUid = token?.umsClientAdmin !== undefined ? token.umsClientAdmin?.uid : token.uid;
      }

      localStorage.setItem("token", sessionToken);
      return dataProvider.getList("users-applications", {
        filter: { userUid },
        pagination: { page: 1, perPage: 100 }
      });
    })
    .then(({ data }) => {
      const umsApplication = data?.find((a: any) => a.application?.key === "ums-client");
      if (umsApplication) {
        return dataProvider.getList(`users-applications/${umsApplication.uid}/modules`, {
          filter: {},
          pagination: { page: 1, perPage: 100 }
        });
      }
      return { data: [] };
    })
    .then(({ data }) => {
      localStorage.setItem("permissions", JSON.stringify(permissionsFromModules(data)));
      return Promise.resolve();
    });
};

const authProvider = {
  login: async ({ username, password }: any) => {
    const request = new Request(`${BASE_URL}/authenticate`, {
      method: "POST",
      mode: "cors",
      body: JSON.stringify({ userName: username, password }),
      headers: JSON_HEADERS
    });

    return fetch(request).then(response => getTokenAndPermissions(response));
  },
  logout: () => {
    clearAuthStorage();
    return Promise.resolve();
  },
  checkAuth: () => (localStorage.getItem("token") ? Promise.resolve() : Promise.reject()),

  checkError: (error: any) => {
    const { status, message } = error;

    // There are cases where permissions will not be set properly.
    // For these cases, we'll just let them slide because the API won't expose sensitive stuff
    // and the UI will just show nothing. If we didn't do this, the UI would log the user out. #BadUX
    const acceptableErrors = [
      "user is unauthorized to use ums-client.ums-ui-service.read-organization",
      "user is unauthorized to use ums-client.ums-ui-service.update-organization",
      "user is unauthorized to use ums-client.ums-ui-service.create-organization"
    ];

    if (acceptableErrors.includes(message)) {
      return Promise.resolve();
    }

    if (status === 401 || status === 403) {
      clearAuthStorage();
      return Promise.reject();
    }

    return Promise.resolve();
  },
  getPermissionsNow: () => {
    return getLocalPermissions();
  },
  getPermissions: () => {
    const permissions = getLocalPermissions();
    return permissions ? Promise.resolve(getLocalPermissions()) : Promise.reject();
  },
  isImpersonating: () => getDecodedToken()?.impersonatingUser ?? false,
  impersonate: async (userUid: string) => {
    const headers = JSON_HEADERS;
    const request = new Request(`${BASE_URL}/users/${userUid}/impersonate`, {
      method: "POST",
      mode: "cors",
      headers
    });

    request.headers.set("Authorization", `JWT ${localStorage.getItem("token")}`);
    return fetch(request).then(response => getTokenAndPermissions(response));
  },
  endImpersonation: async () => {
    const token = getDecodedToken();
    const headers = JSON_HEADERS;
    const request = new Request(`${BASE_URL}/users/${token?.uid}/impersonate`, {
      method: "DELETE",
      mode: "cors",
      headers
    });

    request.headers.set("Authorization", `JWT ${localStorage.getItem("token")}`);
    return fetch(request).then(response => getTokenAndPermissions(response));
  },
  getTokenValue: () => {
    return getDecodedToken();
  }
};

export default authProvider;
