import React from "react";
import {
  TextInput,
  useAuthenticated,
  Edit,
  BooleanInput,
  TabbedForm,
  ReferenceInput,
  AutocompleteInput,
  FormTab,
  required
} from "react-admin";

import SaveOnlyToolbar from "../common/SaveOnlyToolbar";
import AttributeGrid from "../common/AttributeGrid";
import KeyValManager from "../common/KeyValManager";
import globalStyles from "../common/globalStyles";

const redirect = (basePath: string, id: string, data: any) => `/applications/${data.application.uid}/1`;

const EditTitle = ({ record }: any) => {
  return <span>{record ? `${record.name}` : ""}</span>;
};

const ModuleEdit = (props: any) => {
  const styles = globalStyles()
  useAuthenticated();

  return (
    <Edit {...props} successMessage="Module Saved" title={<EditTitle />}>
      <TabbedForm toolbar={<SaveOnlyToolbar />} redirect={redirect}>
        <FormTab label="Summary">
          <TextInput label="name" source="name" validate={[required()]} className={styles.input} />
          <TextInput label="key" source="key" validate={[required()]} className={styles.input} />
          <ReferenceInput
            source="application.uid"
            reference="applications"
            label="Application"
            filter={{ simple: true }}
            validate={[required()]}
            className={styles.input}
          >
            <AutocompleteInput optionText="name" />
          </ReferenceInput>
          <TextInput source="description" multiline className={styles.input} />
          <BooleanInput label="Default Permission" source="defaultPermission" />
        </FormTab>
        <FormTab label="Attributes">
          <AttributeGrid {...props} />
        </FormTab>
        <FormTab label="Programs">
          <KeyValManager {...props} collection="programs" keyLabel="Program" />
        </FormTab>
        <FormTab label="Prerequisites">
          <KeyValManager {...props} collection="prerequisites" keyLabel="Prerequisite" />
        </FormTab>
        <FormTab label="Required Attributes">
          <KeyValManager {...props} collection="required-attributes" keyLabel="Required Attribute" />
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};

export default ModuleEdit;
