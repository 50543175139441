import React, { useCallback } from "react";
import {
  Edit,
  TextInput,
  Datagrid,
  TextField,
  ReferenceManyField,
  Pagination,
  TabbedForm,
  FormTab,
  required,
  email,
  useAuthenticated,
  useMutation,
  useNotify,
  useRefresh,
  useRedirect,
  useDataProvider,
  useAuthProvider,
  useQuery,
  TopToolbar,
  Toolbar,
  SaveButton,
  ListButton,
  CreateButton,
} from "react-admin";

import { makeStyles } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import LoopIcon from "@material-ui/icons/Loop";
import DeleteIcon from "@material-ui/icons/Delete";
import SecurityIcon from "@material-ui/icons/Security";

import AttributeGrid from "../common/AttributeGrid";
import ApplicationManager from "../common/ApplicationManager";
import UserStatusSelect from "./UserStatusSelect";
import UserTypeSelect from "./UserTypeSelect";
import RoleManager from "./RoleManager";
import UserOrganizationQuickCreateButton from "./UserOrganizationQuickCreateButton";
import LoginHistoryTable from "./LoginHistoryTable";
import ImpersonationHistoryTable from "./ImpersonationHistoryTable";
import globalStyles from "../common/globalStyles";

const useStyles = makeStyles({
  deleteColumn: {
    maxWidth: "100px",
    width: "100px"
  }
});

const DeleteUserOrganizationButton = ({ record }: any) => {
  const notify = useNotify();
  const refresh = useRefresh();

  const [del, { loading }] = useMutation(
    {
      type: "delete",
      resource: `users-organizations/${record?.user?.uid}`,
      payload: { id: record.organization.uid }
    },
    {
      onSuccess: ({ data }: any) => {
        notify(`User Organization Removed`, "info");
        refresh();
      },
      onFailure: (_error: any) => notify(`User Application not updated`, "warning")
    }
  );

  return (
    <Button
      size="small"
      disabled={loading}
      onClick={(_e: any) => {
        del();
      }}
    >
      <DeleteIcon color="error" />
      <span color="error">Delete</span>
    </Button>
  );
};

const UserEditTitle = ({ record }: any) => {
  return <span>{record ? `${record.userName}` : ""}</span>;
};

const ImpersonateButton = (props: any) => {
  const authProvider = useAuthProvider();
  const decodedToken = authProvider.getTokenValue();
  const redirect = useRedirect();
  const { record } = props;

  // A user can only impersonate if:
  // 1. They have a ums.admin role
  // 2. They are not already impersonating a user
  // 3. The user they are editing is not the currently logged in user
  // 4. The user they are trying to impersonate also has a ums.admin role
  const canImpersonate = () => {
    if (record) {
      if (decodedToken.uid === record.uid) {
        return false;
      }

      const hasUms = record ? (record.roles || []).find((a: any) => a.roleName === "ums.admin") !== undefined : false;
      return hasUms && !authProvider.isImpersonating();
    }

    return false;
  };

  const impersonate = () =>
    authProvider
      .impersonate(record.uid)
      .then((_response: any) => {
        redirect("/");
      })
      .catch((error: any) => {
        console.log("impersonating error", error.message);
      });
  return (
    <Button disabled={!canImpersonate()} onClick={() => impersonate()} color="primary" size="small">
      <SecurityIcon color={canImpersonate() ? "primary" : "disabled"} />
      Impersonate
    </Button>
  );
};

const SendPasswordButton = (props: any) => {
  const { record } = props;
  const notify = useNotify();
  const dataProvider = useDataProvider();

  const forgotPassword = () =>
    dataProvider
      .forgotPassword({ data: { credential: record.email } })
      .then(({ user }: any) => {
        notify(`Password Sent to ${user.email}`);
      })
      .catch((error: any) => {
        notify(`Password Sending error: ${error.message}`, "warning");
      });

  return (
    <Button onClick={() => forgotPassword()} size="small" color="primary">
      <LoopIcon color="primary" />
      Send Password
    </Button>
  );
};

const UserEditActions = ({ basePath, data, resource }: any) => {
  return (
    <TopToolbar>
      <CreateButton basePath={basePath} record={data} />
      <ListButton basePath={basePath} record={data} />
      <SendPasswordButton record={data} />
      <ImpersonateButton record={data} />
    </TopToolbar>
  );
};

const UserEditToolbar = (props: any) => {
  const { activeRoles, ...rest } = props;
  const { pristine, invalid, basePath } = rest;
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const redirectTo = useRedirect();

  const handleSave = useCallback(
    (values: any, redirect: any) => {
      const { uid, userName, firstName, lastName, email, status, type, roles, role_ids } = values;
      const currentRoleIds = roles.map((r: any) => r.uid);
      const newRoleIds = role_ids.filter((r: string) => !currentRoleIds.includes(r));
      const delRoleIds = currentRoleIds.filter((r: string) => !role_ids.includes(r));

      const makeRole = (roleUid: string) => {
        const role = activeRoles.find((x: any) => x.id === roleUid);
        return {
          roleUid,
          roleName: role?.name ?? "UNDEFINED"
        };
      };

      dataProvider.updateUserWithRoles({
        id: uid,
        data: { userName, firstName, lastName, email, status, type },
        rolesIn: newRoleIds.map(makeRole),
        rolesOut: delRoleIds.map(makeRole)
      }).then((response: any) => {
        notify(`User ${response?.data?.user?.uid} Updated`, "info");
        redirectTo(redirect, basePath, uid, response?.data?.user)
      });
    },
    [notify, redirectTo, basePath, dataProvider, activeRoles]
  );

  return (
    <Toolbar {...rest}><SaveButton disabled={pristine || invalid} onSave={handleSave} /></Toolbar>
  );
};

const UserEdit = (props: any) => {
  const classes = useStyles();
  const styles = globalStyles();
  useAuthenticated();

  const { data, loading, error } = useQuery({
    type: "getList",
    resource: `users`,
    payload: {
      filter: { type: "Role", status: "Active" },
      pagination: { page: 1, perPage: 100 }
    }
  });
  if (loading) {
    return <p>Loading</p>;
  }
  if (error) {
    return <p>ERROR</p>;
  }

  const activeRoles = data.map((u: any) => ({ id: u.uid, name: u.userName }));

  return (
    <div>
      <Edit
        {...props}
        undoable={false}
        successMessage="User Saved"
        title={<UserEditTitle />}
        actions={<UserEditActions />}
      >
        <TabbedForm toolbar={<UserEditToolbar activeRoles={activeRoles} />}>
          <FormTab label="Summary">
            <TextInput disabled label="UID" source="uid" className={styles.input} />
            <TextInput disabled label="Username" source="userName" validate={[required()]} className={styles.input} />
            <TextInput label="First Name" source="firstName" validate={[required()]} className={styles.input} />
            <TextInput label="Last Name" source="lastName" validate={[required()]} className={styles.input} />
            <TextInput
              label="Email Address"
              source="email"
              type="email"
              validate={[required(), email()]}
              className={styles.input}
            />
            <UserStatusSelect source="status" className={styles.input} />
            <UserTypeSelect disabled source="type" className={styles.input} />
            <RoleManager label="Roles" source="role_ids" choices={activeRoles} />
          </FormTab>
          <FormTab label="Attributes">
            <AttributeGrid {...props} />
          </FormTab>
          <FormTab label="Applications">
            <ApplicationManager />
          </FormTab>
          <FormTab label="Organizations">
            <UserOrganizationQuickCreateButton {...props} />
            <ReferenceManyField
              fullWidth
              pagination={<Pagination />}
              reference="users-organizations"
              target="userUid"
              sort={{ field: "organization.name", order: "ASC" }}
              addLabel={false}
            >
              <Datagrid>
                <TextField label="Name" source="organization.name" />
                <TextField label="Type" source="organization.type" />
                <DeleteUserOrganizationButton
                  headerClassName={classes.deleteColumn}
                  cellClassName={classes.deleteColumn}
                />
              </Datagrid>
            </ReferenceManyField>
          </FormTab>
          <FormTab label="Login History">
            <LoginHistoryTable {...props} />
          </FormTab>
          <FormTab label="Impersonation History">
            <ImpersonationHistoryTable {...props} />
          </FormTab>
        </TabbedForm>
      </Edit>
    </div>
  );
};

export default UserEdit;
