import React, { useState } from "react";
import PropTypes from "prop-types";
import { Field, withTypes } from "react-final-form";
//import { useLocation } from "react-router-dom";

import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CircularProgress from "@material-ui/core/CircularProgress";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import LockIcon from "@material-ui/icons/Lock";
import Link from "@material-ui/core/Link";

import { Notification } from "react-admin";
import { useTranslate, useLogin, useNotify } from "ra-core";

const renderInput = ({
  meta: { touched, error } = { touched: false, error: undefined },
  input: { ...inputProps },
  ...props
}: any) => (
  <TextField
    error={!!(touched && error)}
    helperText={touched && error}
    {...inputProps}
    {...props}
    fullWidth
  />
);

const { Form } = withTypes();

const useStyles = makeStyles(theme => ({
  main: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
    alignItems: "center",
    justifyContent: "flex-start",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundImage:
      "radial-gradient(circle at 50% 14em, #313264 0%, #00023b 60%, #00023b 100%)"
  },
  card: {
    minWidth: 350,
    marginTop: "6em"
  },
  avatar: {
    margin: "1em",
    display: "flex",
    justifyContent: "center"
  },
  icon: {
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    padding: "0 1.2em 1.2em 1.2em"
  },
  input: {
    marginTop: "1.2em"
  },
  actions: {
    padding: "0 1em 1em 1em"
  },
  link: {
    marginTop: "1.2em"
  }
}));

const LoginForm = ({ onForgotClicked }: any) => {
  const [loading, setLoading] = useState(false);
  const translate = useTranslate();
  const classes = useStyles();
  const notify = useNotify();
  const login = useLogin();
  //const location = useLocation();

  const handleSubmit = (auth: any) => {
    setLoading(true);

    login(auth, "/").catch(error => {
      setLoading(false);
      notify(
        typeof error === "string"
          ? error
          : typeof error === "undefined" || !error.message
          ? "ra.auth.sign_in_error"
          : error.message,
        "warning"
      );
    });
  };

  const validate = (values: any) => {
    const errors: any = {};
    if (!values.username) {
      errors.username = translate("ra.validation.required");
    }
    if (!values.password) {
      errors.password = translate("ra.validation.required");
    }
    return errors;
  };

  return (
      <Form
        onSubmit={handleSubmit}
        validate={validate}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit} noValidate>
            <div className={classes.main}>
              <Card className={classes.card}>
                <div className={classes.avatar}>
                  <Avatar className={classes.icon}>
                    <LockIcon />
                  </Avatar>
                </div>
                <div className={classes.form}>
                  <div className={classes.input}>
                    <Field
                      autoFocus
                      name="username"
                      component={renderInput}
                      label="Email"
                      disabled={loading}
                    />
                  </div>
                  <div className={classes.input}>
                    <Field
                      name="password"
                      component={renderInput}
                      label={translate("ra.auth.password")}
                      type="password"
                      disabled={loading}
                    />
                  </div>
                </div>
                <CardActions className={classes.actions}>
                  <Button
                    variant="contained"
                    type="submit"
                    color="primary"
                    disabled={loading}
                    fullWidth
                  >
                    {loading && <CircularProgress size={25} thickness={2} />}
                    {translate("ra.auth.sign_in")}
                  </Button>
                </CardActions>
              </Card>
              <Link
                className={classes.link}
                variant="body2"
                component="button"
                type="button"
                onClick={onForgotClicked}
              >
                Forgot Password?
              </Link>
              <Notification />
            </div>
          </form>
        )}
      />
  );
};

LoginForm.propTypes = {
  authProvider: PropTypes.func,
  previousRoute: PropTypes.string,
  onForgotClicked: PropTypes.func,
};

export default LoginForm;
