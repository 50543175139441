import React, { useState, forwardRef } from "react";
import keyBy from "lodash/keyBy";
import { useDataProvider, useQuery, Datagrid, TextField, Loading } from "react-admin";
import ReactJson from "react-json-view";
import MaterialTable from "material-table";

import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { makeStyles } from '@material-ui/core/styles';
import globalStyles from "./globalStyles";

const useStyles = makeStyles(
  _ => ({
    valueCell: {
      maxWidth: "200px",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    }
  }),
  { name: 'ValuegridStyles' }
);

const JsonView = (rowData: any) => {
  // TODO: Sometimes, the data is a javascript primitive, like a boolean...
  return (
    <pre
      style={{
        maxWidth: "200px",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap"
      }}
    >
      {isObject(rowData.value) ? JSON.stringify(rowData.value) : rowData.value}
    </pre>
  );
};

const JsonEdit = (props: any) => {
  const [state, setState] = useState({
    src: { ...props.value },
    view: isObject(props.value) ? "JSON" : "TEXT"
  });

  const change = (e: any) => {
    props.onChange(e.updated_src);
  };

  const changeText = (e: any) => {
    props.onChange(e.target.value);
  };

  const isJsonView = () => {
    return state.view === "JSON";
  };

  return (
    <div>
      <div style={{ marginBottom: "10px" }}>
        <RadioGroup
          aria-label="position"
          name="position"
          value={state.view || "TEXT"}
          onChange={(_: any, v: string) => setState({ ...state, view: v })}
          row
        >
          <FormControlLabel
            value="JSON"
            control={<Radio size="small" color="primary" />}
            label="JSON"
            labelPlacement="end"
          />
          <FormControlLabel
            value="TEXT"
            control={<Radio size="small" color="primary" />}
            label="TEXT"
            labelPlacement="end"
          />
        </RadioGroup>
      </div>
      {isJsonView() ? (
        <ReactJson
          theme="bright:inverted"
          src={isObject(props.value) ? props.value : {}}
          indentWidth={2}
          name={false}
          enableClipboard={false}
          displayDataTypes={false}
          displayObjectSize={false}
          onEdit={change}
          onAdd={change}
          onDelete={change}
        />
      ) : (
        <textarea rows={1} value={isObject(props.value) ? "" : props.value} onChange={changeText} />
      )}
    </div>
  );
};

const ValuePanel = (props: any) => {
  return (
    <pre style={{ backgroundColor: "#efefef", padding: "10px" }}>{JSON.stringify(props.record.value, null, 4)}</pre>
  );
};

const AttributeDatagrid = (props: any) => {
  const {
    resource,
    record: { uid }
  } = props;

  const classes = useStyles(props);
  const styles = globalStyles();

  const { data, loading, error } = useQuery({
    type: "getList",
    resource: `${resource}/${uid}/attributes`,
    payload: {}
  });
  if (loading) {
    return <Loading />;
  }
  if (error) {
    return <p>ERROR</p>;
  }

  const newData = data.map((d: any) => {
    return {
      ...d,
      id: d.key
    };
  });

  return (
    <Datagrid
      expand={<ValuePanel />}
      data={keyBy(newData, "id")}
      ids={newData.map(({ id }: any) => id)}
      currentSort={{ field: "key", order: "ASC" }}
      className={styles.dataGrid}
    >
      <TextField source="key" sortable={false} />
      <TextField source="value" sortable={false} cellClassName={classes.valueCell} />
    </Datagrid>
  );
};

const isObject = (obj: any) => (typeof obj === "object" || typeof obj === "function") && obj !== null;

type AttributeGridProps = {
  resource?: string,
  record?: any,
  readOnly: boolean
};

const AttributeGrid = (props: AttributeGridProps) => {
  const {
    resource,
    readOnly,
    record: { uid }
  } = props;

  const dataProvider = useDataProvider();

  return readOnly ? (
    <AttributeDatagrid {...props} />
  ) : (
    <MaterialTable
      style={{ marginBottom: "40px" }}
      components={{
        Container: props => <div {...props} />
      }}
      icons={{
        Add: forwardRef((props, ref) => <AddIcon {...props} ref={ref} color="primary" />),
        Edit: forwardRef((props, ref) => <EditIcon {...props} ref={ref} color="primary" />),
        Delete: forwardRef((props, ref) => <DeleteIcon {...props} ref={ref} color="primary" />),
        Check: forwardRef((props, ref) => <CheckIcon {...props} ref={ref} color="primary" />),
        Clear: forwardRef((props, ref) => <ClearIcon {...props} ref={ref} color="primary" />)
      }}
      title=""
      columns={[
        { title: "Key", field: "key", editable: "onAdd" },
        {
          title: "Value",
          field: "value",
          render: rowData => <JsonView {...rowData} />,
          editComponent: rowData => <JsonEdit {...rowData} />
        }
      ]}
      options={{
        filtering: false,
        exportButton: false,
        search: false,
        actionsColumnIndex: -1,
        paging: false,
        sorting: false,
        draggable: false
      }}
      data={(_: any) => {
        return new Promise((resolve, _) => {
          let pagination: any = {
            page: 1,
            perPage: 25
          };
          dataProvider.getMany(`${resource}/${uid}/attributes`, { pagination }).then((r: any) => {
            let d = [];
            if (r) {
              d = (r.data || []).map((a: any) => ({
                ...a,
                value: a.value
              }));
            }

            resolve({
              data: d,
              page: 0,
              totalCount: d.length
            });
          });
        });
      }}
      editable={{
        onRowAdd: newData => {
          return dataProvider.update(`${resource}/${uid}/attributes/keys`, {
            data: newData,
            id: newData.key
          });
        },
        onRowUpdate: (newData, _) =>
          dataProvider.update(`${resource}/${uid}/attributes/keys`, {
            data: newData,
            id: newData.key
          }),
        onRowDelete: oldData =>
          dataProvider.delete(`${resource}/${uid}/attributes/keys`, {
            id: oldData.key
          })
      }}
    />
  );
};

export default AttributeGrid;
