import React from "react";
import {
  SelectInput,
} from "react-admin";

const OrgTypeSelect = (props: any) => {

  const choices = [
    { id: 'Agency', name: 'Agency' },
    { id: 'District', name: 'District' },
    { id: 'High School', name: 'High School' },
    { id: 'Institution', name: 'Institution' },
    { id: 'Tech Company', name: 'Tech Company' },
  ];

  return (
    <SelectInput {...props} source="type" choices={choices} defaultValue="" />
  );
};

export default OrgTypeSelect;
