import React from "react";
import { useHistory } from "react-router-dom";
import { parse } from "query-string";
import Button from "@material-ui/core/Button";
import ArrowBack from "@material-ui/icons/ArrowBack";
import {
  useAuthenticated,
  Show,
  TabbedForm,
  FormTab,
  TopToolbar
} from "react-admin";

import AttributeGrid from "../common/AttributeGrid";
import ModuleManager from "../common/ModuleManager";

const UserApplicationEditTitle = ({ name, applicationName }: any) => {
  return (
    <span>
      {name} > {applicationName || "Application"}
    </span>
  );
};

const EditActions = ({ basePath, data, resource }: any) => {
  const history = useHistory();

  const handleClick = (e: any) => {
    e.preventDefault();
    history.push(`/users/${data.userUid}/2`);
  };

  return (
    <TopToolbar>
      <Button color="primary" size="small" onClick={handleClick}>
        <ArrowBack />
        Back To User
      </Button>
    </TopToolbar>
  );
};

const UserApplicationShow = (props: any) => {
  const { resource, location } = props;
  const { search } = location;
  const searchObj = parse(search);
  const { name, applicationName } = searchObj;

  useAuthenticated();
  return (
    <div>
      <Show
        {...props}
        successMessage="User Application Saved"
        actions={<EditActions />}
        title={
          <UserApplicationEditTitle
            {...props}
            name={name}
            applicationName={applicationName}
          />
        }
      >
        <TabbedForm toolbar={null}>
          <FormTab label="Modules">
            <ModuleManager applicationResource={resource} {...props} readOnly={true} />
          </FormTab>
          <FormTab label="Attributes">
            <AttributeGrid {...props} readOnly={true} />
          </FormTab>
        </TabbedForm>
      </Show>
      <br />
    </div>
  );
};

export default UserApplicationShow;
