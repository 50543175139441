import React from "react";
import {
  TextInput,
  useAuthenticated,
  Show,
  BooleanInput,
  TabbedForm,
  ReferenceInput,
  SelectInput,
  FormTab,
  required
} from "react-admin";

import AttributeGrid from "../common/AttributeGrid";
import KeyValManager from "../common/KeyValManager";
import globalStyles from "../common/globalStyles";

const redirect = (basePath: string, id: string, data: any) => `/applications/${data.application.uid}/1`;

const EditTitle = ({ record }: any) => {
  return <span>{record ? `${record.name}` : ""}</span>;
};

const ModuleEdit = (props: any) => {
  const styles = globalStyles()
  useAuthenticated();

  return (
    <Show {...props} title={<EditTitle />} toolbar={null}>
      <TabbedForm toolbar={null} redirect={redirect}>
        <FormTab label="Summary">
          <TextInput disabled label="name" source="name" validate={[required()]} className={styles.input} />
          <TextInput disabled label="key" source="key" validate={[required()]} className={styles.input} />
          <ReferenceInput
            source="application.uid"
            reference="applications"
            label="Application"
            filter={{ simple: true }}
            validate={[required()]}
            className={styles.input}
          >
            <SelectInput disabled optionText="name" />
          </ReferenceInput>
          <TextInput disabled source="description" multiline className={styles.input} />
          <BooleanInput disabled label="Default Permission" source="defaultPermission" />
        </FormTab>
        <FormTab label="Attributes">
          <AttributeGrid {...props} readOnly={true} />
        </FormTab>
        <FormTab label="Programs">
          <KeyValManager {...props} collection="programs" keyLabel="Program" readOnly={true} />
        </FormTab>
        <FormTab label="Prerequisites">
          <KeyValManager {...props} collection="prerequisites" keyLabel="Prerequisite" readOnly={true} />
        </FormTab>
        <FormTab label="Required Attributes">
          <KeyValManager {...props} collection="required-attributes" keyLabel="Required Attribute" readOnly={true} />
        </FormTab>
      </TabbedForm>
    </Show>
  );
};

export default ModuleEdit;
