// Ripped from https://codesandbox.io/s/react-admin-advanced-recipes-quick-createpreview-uqp94

import React from "react";
import { useForm } from "react-final-form";
import {
  fetchEnd,
  fetchStart,
  required,
  showNotification,
  Button,
  SaveButton,
  SimpleForm,
  AutocompleteInput,
  ReferenceInput,
  useRefresh
} from "react-admin";
import IconContentAdd from "@material-ui/icons/Add";
import IconCancel from "@material-ui/icons/Cancel";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Toolbar from "@material-ui/core/Toolbar";

import dataProvider from "../providers/umsDataProvider";

let submitQC: any;
const QCSubmitExtractor = ({ children }: any) => {
  submitQC = useForm().submit;
  return children;
};

export default ({ record }: any) => {
  const refresh = useRefresh();
  const { uid } = record;
  const [showDialog, setShowDialog] = React.useState(false);

  const handleOpenDialog = () => setShowDialog(true);
  const handleCloseDialog = () => setShowDialog(false);

  const handleSave = () => {
    submitQC();
    setShowDialog(false);
  };

  const handleSubmit = (values: any) => {
    let vals = { ...values, userUid: uid };
    fetchStart();
    try {
      dataProvider.create("users-organizations", { data: vals });
      refresh();
      fetchEnd();
    } catch (e) {
      console.error(`error in handleSubmit: ${e.message}`);
      fetchEnd();
      showNotification(e.message, "error");
    }
  };

  return (
    <>
      <Toolbar disableGutters={true}>
        <div style={{ flexGrow: 1 }}></div>
        <Button
          size="small"
          color="primary"
          label="ra.action.create"
          startIcon={<IconContentAdd />}
          onClick={handleOpenDialog}
        />
      </Toolbar>
      {showDialog ? (
        <Dialog fullWidth open={showDialog} onClose={handleCloseDialog} aria-label="Link Organization">
          <DialogTitle>Assign an Organization</DialogTitle>
          <DialogContent>
            <SimpleForm submitOnEnter={false} resource="users-organizations" save={handleSubmit} toolbar={null}>
              <QCSubmitExtractor>
                <ReferenceInput
                  source="organizationUid"
                  reference="organizations"
                  label="Organization"
                  filter={{ simple: true }}
                  sort={{ field: "name", order: "ASC" }}
                  filterToQuery={(searchText: string) => ({ name: searchText })}
                  perPage={5}
                  validate={[required()]}
                >
                  <AutocompleteInput
                    options={{
                      suggestionsContainerProps: { style: { zIndex: 9999 } }
                    }}
                    optionText="name"
                    fullWidth
                  />
                </ReferenceInput>
              </QCSubmitExtractor>
            </SimpleForm>
          </DialogContent>
          <DialogActions>
            <SaveButton onClick={handleSave} handleSubmitWithRedirect={() => {}} />
            <Button label="ra.action.cancel" onClick={handleCloseDialog}>
              <IconCancel />
            </Button>
          </DialogActions>
        </Dialog>
      ) : (
        <span></span>
      )}
    </>
  );
};
