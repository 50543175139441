import React from "react";
import { Toolbar, SaveButton } from "react-admin";

const SaveOnlyToolbar = (props: any) => {
  const { pristine, invalid, readOnly } = props;
  return (
    <Toolbar {...props}>
      {readOnly ? <></> : <SaveButton disabled={pristine || invalid} />}
    </Toolbar>
  );
};

export default SaveOnlyToolbar;
