import UserList from "./UserList";
import UserEdit from "./UserEdit";
import UserShow from "./UserShow";
import UserCreate from "./UserCreate";
import UserIcon from "@material-ui/icons/People";

export default {
  list: UserList,
  edit: UserEdit,
  create: UserCreate,
  show: UserShow,
  icon: UserIcon
};
