import React from "react";
import keyBy from "lodash/keyBy";

import { DateField, TextField, Loading, useQuery, Datagrid } from "react-admin";

const LoginHistoryTable = (props: any) => {
  const { record } = props;
  const { data, loading, error } = useQuery({
    type: "getList",
    resource: `users/${record.id}/impersonate-history`,
    payload: {
      pagination: { page: 1, perPage: 10 },
      sort: { field: "createdAt", order: "asc" }
    }
  });
  if (loading) {
    return <Loading />;
  }
  if (error) {
    return <p>ERROR</p>;
  }
  const newData = data.map((d: any) => {
    return {
      ...d,
      id: d.createdAt
    };
  });

  return newData.length > 0 ? (
    <>
      <Datagrid
        data={keyBy(newData, "id")}
        ids={newData.map(({ id }: any) => id)}
        currentSort={{ field: "createdAt", order: "ASC" }}
      >
        <TextField label="IP Address" source="ipAddress" sortable={false} />
        <TextField label="Impersonator User UID" source="impersonatorUserUid" sortable={false} />
        <DateField label="Created" source="createdAt" sortable={false} showTime />
      </Datagrid>
    </>
  ) : (
    <p style={{textAlign: 'center', padding: 100}}>No Impersonate Records</p>
  );
};

export default LoginHistoryTable;
