import React, { forwardRef } from "react";
import { AppBar, UserMenu, useAuthProvider, useRedirect } from "react-admin";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import SupervisedUserCircleIcon from "@material-ui/icons/SupervisedUserCircle";
import DeleteIcon from "@material-ui/icons/Delete";
import Typography from "@material-ui/core/Typography";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import { makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    flex: 1,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden"
  },
  spacer: {
    flex: 1
  },
  icon: {
    marginRight: "5px"
  },
  listItemIcon: {
    minWidth: theme.spacing(5)
  }
}), {name: 'CustomAppBar'});

const properCaseName = (name: string) => {
  return name
    .split("")
    .map((c, i) => {
      return i === 0 ? c.toUpperCase() : c.toLowerCase();
    })
    .join("");
};

const UserButton = () => {
  const classes = useStyles();
  const authProvider = useAuthProvider();
  let firstName = authProvider.getTokenValue()?.firstName;
  firstName = properCaseName(firstName || "");
  return (
    <>
      {authProvider.isImpersonating() ? (
        <SupervisedUserCircleIcon className={classes.icon} color="error" />
      ) : (
        <AccountCircleIcon className={classes.icon} />
      )}
      {firstName ? `Hello, ${firstName}` : ""}
    </>
  );
};

const ImpersonationMenu = forwardRef<any, any>((props, ref) => {
  const redirect = useRedirect();
  const classes = useStyles();
  const authProvider = useAuthProvider();
  const endImpersonation = (_event: any) => {
    props.onClick();
    return authProvider.endImpersonation().then(() => redirect("/?end"));
  };
  return (
    <MenuItem ref={ref} onClick={endImpersonation}>
      <ListItemIcon className={classes.listItemIcon}><DeleteIcon color="error" /></ListItemIcon>
      End Impersonation
    </MenuItem>
  );
});

const CustomUserMenu = (props: any) => {
  const authProvider = useAuthProvider();
  return (
    <UserMenu {...props} icon={<UserButton {...props} />}>
      { authProvider.isImpersonating() ? <ImpersonationMenu /> : null}
    </UserMenu>
  );
};

const CustomAppBar = (props: any) => {
  const classes = useStyles(props);
  const authProvider = useAuthProvider();
  return (
    <AppBar {...props} userMenu={<CustomUserMenu isImpersonating={authProvider.isImpersonating()} />}>
      <Typography variant="h6" color="inherit" className={classes.title} id="react-admin-title" />
      <h3>User Management System</h3>
      <span className={classes.spacer} />
    </AppBar>
  );
};

export default CustomAppBar;
