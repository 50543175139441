import ApplicationList from "./ApplicationList";
import ApplicationEdit from "./ApplicationEdit";
import ApplicationShow from "./ApplicationShow";
import ApplicationCreate from "./ApplicationCreate";
import WebAsset from "@material-ui/icons/WebAsset";

export default {
  list: ApplicationList,
  edit: ApplicationEdit,
  show: ApplicationShow,
  create: ApplicationCreate,
  icon: WebAsset
};
