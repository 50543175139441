import React from "react";
import { useNotify, useMutation } from "react-admin";
import { Link } from "react-router-dom";
import Switch from "@material-ui/core/Switch";
import Button from "@material-ui/core/Button";
import { DatagridColumn } from "../types";

type ApplicationSwitchProps = {
  record?: any,
  resource?: string,
  resourceId: string,
  onChange: Function,
  readOnly: boolean,
};

const ApplicationEnableSwitch = (props: ApplicationSwitchProps) => {
  const { record, resource, resourceId, onChange, readOnly } = props;
  const { applicationUid } = record;
  const notify = useNotify();

  const getPayload = () => {
    let payload = { applicationUid };
    switch (resource) {
      case "users":
        return { data: { ...payload, userUid: resourceId } };
      case "organizations":
        return { data: { ...payload, organizationUid: resourceId } };
      default:
        return { data: { ...payload } };
    }
  };

  const getUid = (data: any) => {
    switch (resource) {
      case "users":
        return data.userApplication.uid;
      case "organizations":
        return data.organizationApplication.uid;
      default:
        return "";
    }
  };

  const [create, { loading }] = useMutation(
    {
      type: "create",
      resource: `${resource}-applications`,
      payload: getPayload()
    },
    {
      onSuccess: (response: any) => {
        const { data } = response;
        onChange({ uid: getUid(data), resourceId, applicationUid });
      },
      onFailure: (error: any) => {
        console.error(error);
        notify(`${resource} Application not updated`, "warning");
      }
    }
  );

  return (
    <Switch
      color="primary"
      key={record.id}
      checked={false}
      onChange={create}
      disabled={loading || readOnly}
      inputProps={{ "aria-label": "disable application" }}
    />
  );
};

const ApplicationDisableSwitch = (props: ApplicationSwitchProps) => {
  const { record, resource, resourceId, onChange, readOnly } = props;
  const { uid, applicationUid } = record;
  const notify = useNotify();

  const [del, { loading }] = useMutation(
    {
      type: "delete",
      resource: `${resource}-applications`,
      payload: { id: uid }
    },
    {
      onSuccess: (_: any) => {
        onChange({ uid: null, resourceId, applicationUid });
      },
      onFailure: (error: any) => {
        console.error(error);
        notify(`${resource} Application not updated`, "warning");
      }
    }
  );

  return (
    <Switch
      color="primary"
      key={record.id}
      checked={true}
      onChange={del}
      disabled={loading || readOnly}
      inputProps={{ "aria-label": "enable application" }}
    />
  );
};

type ApplicationEditButtonProps = {
  uid: string,
  name: string,
  applicationName: string,
  resource?: string,
  readOnly?: boolean,
};

const ApplicationEditButton = (props: ApplicationEditButtonProps) => {
  const { uid, name, applicationName, resource, readOnly } = props;
  const show = readOnly ? '/show' : '';
  const appPath = `/${resource}-applications/${uid}${show}`;

  if (uid) {
      return (
        <Button
          component={Link}
          to={{
            pathname: appPath,
            search: `name=${name}&applicationName=${applicationName}`
          }}
          color="primary"
        >
          Manage
        </Button>
    );
  } else {
    return <span></span>;
  }
};

type ApplicationAssignmentSwitchProps = DatagridColumn & {
  onSwitched?: Function,
  record?: any,
  name: string,
  resourceId: string,
  applicationResource?: string,
  readOnly: boolean,
};

const ApplicationAssignmentSwitch = (props: ApplicationAssignmentSwitchProps) => {
  const { record, name, resourceId, applicationResource, readOnly } = props;
  const { applicationAssignmentUid, applicationUid, id } = record;
  const [selected, setSelected] = React.useState({
    id,
    uid: applicationAssignmentUid,
    resourceId,
    applicationUid
  });

  if (selected.uid) {
    return (
      <>
        <ApplicationDisableSwitch
          record={selected}
          resource={applicationResource}
          resourceId={resourceId}
          onChange={(r: any) => setSelected(r)}
          readOnly={readOnly}
        />
        <ApplicationEditButton
          uid={selected.uid}
          name={name}
          resource={applicationResource}
          applicationName={record.name}
          readOnly={readOnly}
        />
      </>
    );
  }
  return (
    <ApplicationEnableSwitch
      record={selected}
      resource={applicationResource}
      resourceId={resourceId}
      onChange={(r: any) => setSelected(r)}
      readOnly={readOnly}
    />
  );
};

export default ApplicationAssignmentSwitch;
