import React, { useState } from "react";

import LoginForm from "./LoginForm";
import ForgotPasswordForm from "./ForgotPasswordForm";

export default () => {
  const [forgot, setForgot] = useState(false);

  if (forgot) {
    return <ForgotPasswordForm onForgotRequested={() => setForgot(false)} />;
  } else {
    return <LoginForm onForgotClicked={() => setForgot(true)} />;
  }
};
