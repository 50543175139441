import React from "react";
import {
  List,
  Datagrid,
  TextField,
  Filter,
  TextInput,
  EmailField,
  DeleteButton,
  useAuthenticated
} from "react-admin";

import UserStatusSelect from "./UserStatusSelect";
import UserTypeSelect from "./UserTypeSelect";

const UserFilter = (props: any) => (
  <Filter {...props}>
    <TextInput source="firstName" alwaysOn resettable={true}/>
    <TextInput source="lastName" alwaysOn resettable={true}/>
    <TextInput source="email" alwaysOn resettable={true}/>
    <UserStatusSelect source="status" alwaysOn resettable={true}/>
    <UserTypeSelect source="type" alwaysOn resettable={true}/>
  </Filter>
);

const UserList = (props: any) => {
  useAuthenticated();
  const readOnly = props?.permissions?.readOnly && true;
  const showOrEdit = readOnly ? 'show' : 'edit';
  return (
    <List
      {...props}
      filters={<UserFilter />}
      perPage={10}
      exporter={false}
      bulkActionButtons={false}
      sort={{ field: "userName", order: "asc" }}
      filter={{ simple: true }}
    >
      <Datagrid rowClick={showOrEdit}>
        <TextField source="userName" />
        <TextField source="firstName" />
        <TextField source="lastName" />
        <EmailField source="email" />
        <TextField source="status" />
        <TextField source="type" />
        {readOnly ? null : (<DeleteButton undoable={false}/>)}
      </Datagrid>
    </List>
  );
};

export default UserList;
