import React  from "react";
import { AutocompleteArrayInput } from "react-admin";
import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles({
  container: {
    width: 550
  }
});

const RoleManager = (props: any) => {
  return <AutocompleteArrayInput {...props} fullWidth classes={styles()} />;
};

export default RoleManager;
