import React from "react";
import { useHistory } from "react-router-dom";
import {
  TextInput,
  useAuthenticated,
  Edit,
  Datagrid,
  EditButton,
  TabbedForm,
  FormTab,
  TextField,
  Pagination,
  ReferenceManyField,
  TopToolbar,
  ListButton,
  required
} from "react-admin";

import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import ContentAdd from "@material-ui/icons/Add";

import globalStyles from "../common/globalStyles";
import SaveOnlyToolbar from "../common/SaveOnlyToolbar";
import AttributeGrid from "../common/AttributeGrid";
import ModulePermissionSwitch from "../common/ModulePermissionSwitch";
import { validateUrl } from "../common/validators";

const EditActions = (props: any) => {
  const { basePath, data } = props;
  return (
    <TopToolbar>
      <ListButton basePath={basePath} record={data} />
    </TopToolbar>
  );
};

const CreateModuleButton = (props: any) => {
  const history = useHistory();
  const { record } = props;

  const handleClick = (e: React.MouseEvent) => {
    e.preventDefault();
    history.push(`/modules/create?applicationUid=${record.uid}`);
  };

  return record ? (
    <Toolbar disableGutters={true}>
      <div style={{ flexGrow: 1 }}></div>
      <Button size="small" color="primary" startIcon={<ContentAdd />} onClick={handleClick}>
        CREATE
      </Button>
    </Toolbar>
  ) : (
    <span></span>
  );
};

const EditTitle = (props: any) => {
  const { record } = props;
  return <span>{record ? `${record.name}` : ""}</span>;
};

const ApplicationEdit = (props: any) => {
  const styles = globalStyles();
  useAuthenticated();
  return (
    <div>
      <Edit {...props} successMessage="Application Saved" title={<EditTitle />} actions={<EditActions />}>
        <TabbedForm toolbar={<SaveOnlyToolbar />}>
          <FormTab label="Summary">
            <TextInput source="name" validate={[required()]} className={styles.input} />
            <TextInput
              source="key"
              parse={(v: string) => v.toLowerCase()}
              format={(v: string) => v.toLowerCase()}
              validate={[required()]}
              className={styles.input}
            />
            <TextInput
              source="url"
              helperText="http(s)://yourdomain.com"
              validate={[required(), validateUrl]}
              className={styles.input}
            />
            <TextInput
              label="Admin URL"
              helperText="http(s)://yourdomain.com"
              source="adminUrl"
              validate={[required(), validateUrl]}
              className={styles.input}
            />
          </FormTab>
          <FormTab label="Modules">
            <CreateModuleButton />
            <ReferenceManyField
              pagination={<Pagination />}
              fullWidth
              reference="modules"
              target="applicationUid"
              sort={{ field: "name", order: "ASC" }}
              addLabel={false}
            >
              <Datagrid>
                <TextField source="name" />
                <TextField source="key" />
                <ModulePermissionSwitch source="defaultPermission" label="Default Permission" textAlign="center" />
                <EditButton />
              </Datagrid>
            </ReferenceManyField>
          </FormTab>
          <FormTab label="Attributes">
            <AttributeGrid {...props} />
          </FormTab>
        </TabbedForm>
      </Edit>
      <br />
    </div>
  );
};

export default ApplicationEdit;
