import React from "react";
import {
  SelectInput,
} from "react-admin";

const UserRoleSelect = (props: any) => {

  const choices = [
    { id: 'User', name: 'User' },
    { id: 'Role', name: 'Role' },
    { id: 'LdapUser', name: 'LdapUser' },
  ];

  return (
    <SelectInput {...props} choices={choices} defaultValue="" />
  );
};

export default UserRoleSelect;
