import React from "react";
import {
  List,
  Datagrid,
  TextField,
  Filter,
  TextInput,
  BooleanField,
  ReferenceField,
  ReferenceInput,
  AutocompleteInput,
  useAuthenticated
} from "react-admin";

const ModuleFilter = (props: any) => (
  <Filter {...props}>
    <TextInput source="name" alwaysOn />
    <ReferenceInput
      resettable={true}
      label="Application"
      source="applicationUid"
      reference="applications"
      filterToQuery={(searchText: string) => ({ name: [searchText] })}
      perPage={5}
    >
      <AutocompleteInput source="name" />
    </ReferenceInput>
  </Filter>
);

const ModuleList = (props: any) => {
  useAuthenticated();
  const readOnly = props?.permissions?.readOnly && true;
  const showOrEdit = readOnly ? 'show' : 'edit';
  return (
    <List
      {...props}
      filters={<ModuleFilter />}
      sort={{ field: "name", order: "asc" }}
      perPage={25}
      exporter={false}
      bulkActionButtons={false}
    >
      <Datagrid rowClick={showOrEdit}>
        <TextField source="name" />
        <TextField source="key" />
        <ReferenceField
          sortable={false}
          label="Application"
          source="application.uid"
          reference="applications"
        >
          <TextField source="name" />
        </ReferenceField>
        <BooleanField sortable={false} source="defaultPermission" label="Default Permission" />
      </Datagrid>
    </List>
  );
};

export default ModuleList;
