import React from "react";
import { Layout } from "react-admin";
import AppBar from "./AppBar";
import Menu from "./Menu";

export const umsTheme = {
  palette: {
    primary: {
      main: '#0098c3'
    },
    secondary: {
      main: '#003359'
    }
  }
};

export default (props: any) => {
  return (
    <Layout
      {...props}
      menu={Menu}
      theme={umsTheme}
      appBar={AppBar}
    />
  );
};
