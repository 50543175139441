import React from "react";
import { Create, TextInput, SimpleForm, required, email, useAuthenticated, ListButton, TopToolbar } from "react-admin";

import SaveOnlyToolbar from "../common/SaveOnlyToolbar";
import UserStatusSelect from "./UserStatusSelect";
import UserTypeSelect from "./UserTypeSelect";
import globalStyles from "../common/globalStyles";

const UserCreateActions = ({ basePath, data, resource }: any) => {
  return (
    <TopToolbar>
      <ListButton basePath={basePath} record={data} />
    </TopToolbar>
  );
};

const UserCreate = (props: any) => {
  const styles = globalStyles();
  useAuthenticated();
  return (
    <div>
      <Create {...props} successMessage="User Created" actions={<UserCreateActions />}>
        <SimpleForm toolbar={<SaveOnlyToolbar />} className={styles.simpleForm} >
          <TextInput label="Username" source="userName" validate={[required()]} fullWidth/>
          <TextInput label="First Name" source="firstName" validate={[required()]} fullWidth/>
          <TextInput label="Last Name" source="lastName" validate={[required()]} fullWidth/>
          <TextInput label="Email Address" source="email" type="email" validate={[required(), email()]} fullWidth/>
          <UserStatusSelect source="status" fullWidth/>
          <UserTypeSelect source="type" fullWidth/>
        </SimpleForm>
      </Create>
    </div>
  );
};

export default UserCreate;
