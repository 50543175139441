import React from "react";
import {
  Edit,
  TabbedForm,
  FormTab,
  TextInput,
  useAuthenticated,
  TopToolbar,
  ListButton,
  ReferenceInput,
  SelectInput
} from "react-admin";

import SaveOnlyToolbar from "../common/SaveOnlyToolbar";
import AttributeGrid from "../common/AttributeGrid";
import UserGrid from "../common/UserGrid";
import ApplicationManager from "../common/ApplicationManager";
import KeyValManager from "../common/KeyValManager";
import globalStyles from "../common/globalStyles";

const EditTitle = ({ record }: any) => {
  return <span>{record ? `${record.name}` : ""}</span>;
};

const EditActions = ({ basePath, data }: any) => (
  <TopToolbar>
    <ListButton basePath={basePath} record={data} />
  </TopToolbar>
);

const OrganizationEdit = (props: any) => {
  const readOnly = true;
  const styles = globalStyles();
  useAuthenticated();
  return (
    <Edit
      {...props}
      undoable={false}
      successMessage="Organization Saved"
      filter={{ simple: true }}
      title={<EditTitle />}
      actions={<EditActions />}
    >
      <TabbedForm toolbar={<SaveOnlyToolbar readOnly={readOnly} />}>
        <FormTab label="Summary">
            <TextInput source="name" className={styles.input} />

            <SelectInput
              source="type"
              choices={[
                { id: "Organization", name: "Organization" },
                { id: "OrgType", name: "OrgType" }
              ]}
              className={styles.input}
            />

            <SelectInput
              source="status"
              choices={[
                { id: "Active", name: "Active" },
                { id: "Inactive", name: "Inactive" },
                { id: "Pending", name: "Pending" }
              ]}
              className={styles.input}
            />

            <TextInput label="External ID" source="externalId" className={styles.input} />
            <TextInput label="SF Account ID" source="sfAccountId" className={styles.input} />
            <TextInput label="ACT Code" source="actCode" className={styles.input} />
            <ReferenceInput
              source="group.uid"
              reference="organizations"
              filter={{ simple: true, orgType: 'OrgType' }}
              sort={{ field: "name", order: "ASC" }}
              perPage={5}
              label="Org Type"
              className={styles.input}
            >
              <SelectInput optionText="name" />
            </ReferenceInput>
        </FormTab>
        <FormTab label="Applications">
          <ApplicationManager readOnly={readOnly} />
        </FormTab>
        <FormTab label="Attributes">
          <AttributeGrid readOnly={readOnly} {...props} />
        </FormTab>
        <FormTab label="Programs">
          <KeyValManager readOnly={readOnly} {...props} collection="programs" keyLabel="Program" />
        </FormTab>
        <FormTab label="Users">
          <UserGrid {...props} />
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};

export default OrganizationEdit;
