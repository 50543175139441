import React from "react";
import {
  TextField,
  Show,
  TabbedShowLayout,
  Tab,
  useAuthenticated,
  TopToolbar,
  ListButton,
  ReferenceField,
  SelectField
} from "react-admin";

import AttributeGrid from "../common/AttributeGrid";
import ApplicationManager from "../common/ApplicationManager";
import UserGrid from "../common/UserGrid";
import KeyValManager from "../common/KeyValManager";
import globalStyles from "../common/globalStyles";

const EditTitle = ({ record }: any) => {
  return <span>{record ? `${record.name}` : ""}</span>;
};

const EditActions = ({ basePath, data }: any) => (
  <TopToolbar>
    <ListButton basePath={basePath} record={data} />
  </TopToolbar>
);

const OrganizationShow = (props: any) => {
  const readOnly = true;
  const styles = globalStyles();
  useAuthenticated();
  return (
    <Show
      {...props}
      filter={{ simple: true }}
      title={<EditTitle />}
      actions={<EditActions />}
    >
      <TabbedShowLayout toolbar={null}>
        <Tab label="Summary">
            <TextField disabled source="name" className={styles.input} />

            <SelectField
              source="type"
              disabled
              choices={[
                { id: "Organization", name: "Organization" },
                { id: "OrgType", name: "OrgType" }
              ]}
              className={styles.input}
            />

            <SelectField
              source="status"
              disabled
              choices={[
                { id: "Active", name: "Active" },
                { id: "Inactive", name: "Inactive" },
                { id: "Pending", name: "Pending" }
              ]}
              className={styles.input}
            />

            <TextField disabled label="External ID" source="externalId" className={styles.input} />
            <TextField disabled label="SF Account ID" source="sfAccountId" className={styles.input} />
            <TextField disabled label="ACT Code" source="actCode" className={styles.input} />
            <ReferenceField
              source="group.uid"
              reference="organizations"
              filter={{ simple: true, orgType: 'OrgType' }}
              sort={{ field: "name", order: "ASC" }}
              label="Org Type"
              disabled
              className={styles.input}
            >
              <TextField source="name" />
            </ReferenceField>
        </Tab>
        <Tab label="Applications">
          <ApplicationManager readOnly={readOnly} />
        </Tab>
        <Tab label="Attributes">
          <AttributeGrid readOnly={readOnly} {...props} />
        </Tab>
        <Tab label="Programs">
          <KeyValManager readOnly={readOnly} {...props}
                         collection="programs" keyLabel="Program" />
        </Tab>
        <Tab label="Users">
          <UserGrid {...props} />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

export default OrganizationShow;
