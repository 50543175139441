import React from "react";
import { List, Datagrid, TextField, Filter, TextInput } from "react-admin";

import globalStyles from "./globalStyles";

const UserOrganizationFilter = (props: any) => (
  <Filter {...props}>
    <TextInput source="firstName" alwaysOn />
    <TextInput source="lastName" alwaysOn />
  </Filter>
);

const UserDatagrid = (props: any) => {
  const {
    record: { uid }
  } = props;

  const styles = globalStyles();

    const newProps = {
        hasShow: false,
        hasEdit: false,
        hasCreate: false,
        hasList: true,
        basePath: 'users-organizations',
        resource: 'users-organizations',
    };

    return (
      <List
        {...newProps }
        perPage={25}
        exporter={false}
        bulkActionButtons={false}
        filters={<UserOrganizationFilter />}
        filter={{ organizationUid: uid }}>
        <Datagrid className={styles.dataGrid}>
          <TextField label="First Name" source="user.firstName" sortable={false} />
          <TextField label="Last Name" source="user.lastName" sortable={false} />
          <TextField label="Username" source="user.userName" sortable={false} />
          <TextField label="Email" source="user.email" sortable={false} />
        </Datagrid>
      </List>);
};


type GridProps = {
  resource?: string,
  record?: any,
  readOnly: boolean
};

const UserGrid = (props: GridProps) => {
  return <UserDatagrid {...props} />
};

export default UserGrid;
