import { fetchUtils } from "react-admin";
import { stringify } from "query-string";
import {
  GetParams,
  GetArrayParams,
  UpdateParams,
  UpdateParamsWithRoles,
  ForgotPasswordParams,
  IHttpResponse,
  UmsQuery
} from "../types";
import { BASE_URL, JSON_HEADER_OBJ } from "../constants";

const httpClient = (url: string, options: any = {}) => {
  const token = localStorage.getItem("token");
  const headers = new Headers({ ...JSON_HEADER_OBJ, ...options.headers });

  for (const headerKey of Object.keys(options.headers || {})) {
    headers.set(headerKey, options.headers[headerKey]);
  }

  if (token) {
    headers.set("Authorization", `JWT ${token}`);
  }

  options.headers = headers;
  return fetchUtils.fetchJson(url, options);
};

const getPostBody = (resource: string, params: any, json: any) => {
  switch (resource) {
    case "applications":
      return json.application ? { ...json.application, id: json.application.uid } : { ...params.data };
    case "users":
      return json.user ? { ...json.user, id: json.user.uid } : { ...params.data };
    case "organizations":
      return json.organization ? { ...json.organization, id: json.organization.uid } : { ...params.data };
    case "modules":
      return json.modules ? { ...json.modules, id: json.modules.uid } : { ...params.data };
    default:
      return json ? { ...json, id: json.id } : { ...params.data };
  }
};

const getUpdateBody = (resource: string, params: any) => {
  switch (resource) {
    case "organizations":
      return {
        ...params,
        ...{ data: { ...params['data'], orgType: params['data']['group']['uid'] }},
      }
    default:
      return { ...params }
  }
}

const arrayParamsToUmsQuery = (resource: string, params: GetArrayParams): Partial<UmsQuery> => {
  const { pagination, sort, filter, ids } = params;

  let query: Partial<UmsQuery> = {};

  if (pagination) {
    query.offset = (pagination.page - 1) * pagination.perPage;
    query.limit = pagination.perPage;
  }

  if (sort) {
    query.sort = `${sort.field}(${sort.order.toLowerCase()})`;
  }

  if (ids && resource !== 'organizations') {
    const f = { userName: (ids ?? []).map((u: any) => u.roleName).join("|") };
    query = { ...query, ...f };
  }

  if (filter) {
    // Our api does not support filtering by many ids, but perhaps it should
    let newFilter = { ...filter };
    if (newFilter.application) {
      newFilter.applicationUid = filter.application.uid;
    }
    query = { ...query, ...newFilter };
  }

  return query;
};

export default {
  getList: async (resource: string, params: GetArrayParams) => {
    const query = arrayParamsToUmsQuery(resource, params);
    const url = `${BASE_URL}/${resource}?${stringify(query)}`;
    return httpClient(url).then(({ headers, json }: IHttpResponse) => {
      return {
        data: json.map((i: any) => {

          let result = resource === 'users-organizations' ?
            { ...i, id: i.user.userName } :
            { ...i, id: i.uid };

          if (i.application) {
            result.application_uid = i.application.uid;
            result.application_name = i.application.name;
          }
          return result;
        }),
        total: parseInt(headers.get("total-count") ?? 0, 10)
      };
    });
  },

  getOne: async (resource: string, params: GetParams) => {
    return httpClient(`${BASE_URL}/${resource}/${params.id}`).then(({ json }: IHttpResponse) => {
      const newJson = { ...json, id: json.uid || params.id };
      if (newJson.roles) {
        newJson.role_ids = newJson.roles.map((r: any) => r.uid);
      }
      return { data: newJson };
    });
  },

  getMany: async (resource: string, params: GetArrayParams) => {

    const query = arrayParamsToUmsQuery(resource, params);

    // HACK: no way to filter when getting a reference
    if (["applications"].includes(resource)) {
      query.simple = true;
    }

    const url = `${BASE_URL}/${resource}?${stringify(query)}`;
    return httpClient(url).then(({ json }: IHttpResponse) => ({
      data: json.map((d: any) => {
        let result = { ...d, id: d.uid };
        return result;
      })
    }));
  },

  getManyReference: async (resource: string, params: any) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const { filter, target } = params;

    const getIsSimple = () => {
      return !["users-organizations", "organizations-applications"].includes(resource);
    };

    let query: Partial<UmsQuery> = {
      sort: `${field}(${order})`,
      offset: (page - 1) * perPage,
      limit: perPage,
      [target]: params.id
    };

    if (filter) {
      query = { ...query, ...filter };
    }

    if (getIsSimple()) {
      query.simple = true;
    }

    const url = `${BASE_URL}/${resource}?${stringify(query)}`;

    return httpClient(url).then(({ headers, json }: IHttpResponse) => ({
      data: json.map((i: any) => {
        if (resource === "users-organizations") {
          return { ...i, id: i.organization ? i.organization.uid : "-" };
        }

        return { ...i, id: i.uid };
      }),
      total: parseInt(headers.get("total-count") ?? 0, 10)
    }));
  },

  update: (resource: string, params: UpdateParams) => {

    let updatedParams = getUpdateBody(resource, params);

    return httpClient(`${BASE_URL}/${resource}/${params.id}`, {
      method: "PUT",
      body: JSON.stringify(updatedParams.data)
    }).then((response: IHttpResponse) => {
      if (response) {
        return { data: response.json };
      } else {
        return params.data;
      }
    });
  },

  updateMany: (resource: string, params: any) =>
    Promise.all(
      params.ids.map((id: string) =>
        httpClient(`${BASE_URL}/${resource}/${id}`, {
          method: "PUT",
          body: JSON.stringify(params.data)
        })
      )
    ).then(responses => ({
      data: responses.map((_response: any) => {
        return "x"; // The response does not return anything, but react-admin wants it to
      })
    })),

  create: (resource: string, params: any) =>
    httpClient(`${BASE_URL}/${resource}`, {
      method: "POST",
      body: JSON.stringify(params.data)
    }).then(({ json }: IHttpResponse) => ({
      data: getPostBody(resource, params, json)
    })),

  delete: (resource: string, params: any) => {
    const url = params.id ? `${BASE_URL}/${resource}/${params.id}` : `${BASE_URL}/${resource}`;

    if (params.body) {
      return httpClient(url, {
        method: "DELETE",
        body: JSON.stringify(params.body)
      }).then(({ json }: any) => ({ data: json }));
    }

    return httpClient(url, {
      method: "DELETE"
    }).then(({ json }: any) => ({ data: json }));
  },

  deleteMany: (resource: string, params: any) =>
    Promise.all(
      params.ids.map((id: string) =>
        httpClient(`${BASE_URL}/${resource}/${id}`, {
          method: "DELETE"
        })
      )
    ).then(responses => ({
      data: responses.map((_response: any) => {
        return "x"; // The response does not return anything, but react-admin wants it to
      })
    })),

  forgotPassword: (params: ForgotPasswordParams) =>
    httpClient(`${BASE_URL}/forgot-password`, {
      method: "POST",
      body: JSON.stringify(params.data),
      headers: { "Send-Email": "allowed" }
    }).then(({ json }: IHttpResponse) => ({ data: json })),

  updateUserWithRoles: async (params: UpdateParamsWithRoles) => {
    return Promise.all(
      [...(params.rolesIn || []).map((role: any) => {
        return httpClient(`${BASE_URL}/roles`, {
          method: "POST",
          body: JSON.stringify({...role, userUid: params.id})
        })
      }),
      ...(params.rolesOut || []).map((role: any) => {
        return httpClient(`${BASE_URL}/roles`, {
          method: "DELETE",
          body: JSON.stringify({...role, userUid: params.id})
        })
      })]
    ).then(() => {
      return httpClient(`${BASE_URL}/users/${params.id}`, {
        method: "PUT",
        body: JSON.stringify(params.data)
      })
    }).then((response: IHttpResponse) => {
      if (response) {
        return { data: response.json };
      } else {
        return { data: params.data };
      }
    });
  }
};
