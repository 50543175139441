import React from "react";
import { useNotify, useMutation } from "react-admin";
import Switch from "@material-ui/core/Switch";
import Button from "@material-ui/core/Button";
import CheckboxIcon from "@material-ui/icons/CheckBox";
import CheckboxOutlineIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import { DatagridColumn } from "../types";

type OverwritePermissionCheckbox = {
  record: any,
  resource: string,
  resourceId: string,
  onChange: Function,
  readOnly: boolean,
};

const OverwritePermissionCheckbox = ({ record, resource, resourceId, onChange, readOnly }: OverwritePermissionCheckbox) => {
  const { moduleUid } = record;
  const notify = useNotify();

  const [update, { loading }] = useMutation(
    {
      type: "update",
      resource: `${resource}/${resourceId}/modules`,
      payload: {
        id: moduleUid,
        data: { permissionOverwrite: !record.permissionOverwrite }
      }
    },
    {
      onSuccess: ({ data }: any) => {
        switch (resource) {
          case "users-applications":
            onChange({
              ...record,
              permissionOverwrite: data.userApplicationModule.permissionOverwrite
            });
            break;
          case "organizations-applications":
            onChange({
              ...record,
              permissionOverwrite: data.organizationApplicationModule.permissionOverwrite
            });
            break;
          default:
            console.error("unsupported resource");
        }
      },
      onFailure: (error: any) => {
        console.error(error);
        notify(`${resource} not updated`, "warning");
      }
    }
  );

  return record.permissionOverwrite ? (
    <Button size="small" color="default" disabled={loading || readOnly} onClick={update}>
      <CheckboxIcon color="primary" />
      Permission Overwrite
    </Button>
  ) : (
    <Button size="small" color="default" disabled={loading || readOnly} onClick={update}>
      <CheckboxOutlineIcon color="primary" />
      Permission Overwrite
    </Button>
  );
};

type ModuleSwitchProps = {
  record?: any,
  resource: string,
  resourceId: string,
  onChange: Function,
  readOnly: boolean,
};

const ModuleEnableSwitch = ({ record, resource, resourceId, onChange, readOnly }: ModuleSwitchProps) => {
  const { moduleUid } = record;
  const notify = useNotify();

  const [create, { loading }] = useMutation(
    {
      type: "create",
      resource: `${resource}/${resourceId}/modules/${moduleUid}`,
      payload: { data: {} }
    },
    {
      onSuccess: (_: any) => {
        onChange({ ...record, isOn: true });
      },
      onFailure: (error: any) => {
        console.error(error);
        notify(`${resource} not updated`, "warning");
      }
    }
  );

  return (
    <Switch
      color="primary"
      key={record.id}
      checked={false}
      onChange={create}
      disabled={loading || readOnly}
      inputProps={{ "aria-label": "disable application" }}
    />
  );
};

const ModuleDisableSwitch = ({ record, resource, resourceId, onChange, readOnly }: ModuleSwitchProps) => {
  const { moduleUid } = record;
  const notify = useNotify();

  const [del, { loading }] = useMutation(
    {
      type: "delete",
      resource: `${resource}/${resourceId}/modules`,
      payload: { id: moduleUid }
    },
    {
      onSuccess: (_: any) => {
        onChange({ ...record, isOn: false });
      },
      onFailure: (error: any) => {
        console.error(error);
        notify(`${resource} not updated`, "warning");
      }
    }
  );

  return (
    <Switch
      color="primary"
      key={record.id}
      checked={true}
      onChange={del}
      disabled={loading || readOnly}
      inputProps={{ "aria-label": "enable application" }}
    />
  );
};

type ModuleAssignmentSwitchProps = DatagridColumn & {
  record?: any,
  applicationResource: string,
  resourceId: string,
  readOnly: boolean,
};

const ModuleAssignmentSwitch = ({ record, applicationResource, resourceId, readOnly }: ModuleAssignmentSwitchProps) => {
  const { uid, isOn, application, applicationModule } = record;

  const [selected, setSelected] = React.useState({
    permissionOverwrite: applicationModule ? applicationModule.permissionOverwrite : false,
    moduleUid: uid,
    applicationUid: application.uid,
    isOn
  });

  const handleOnSwitch = (r: any) => {
    setSelected({
      permissionOverwrite: r.permissionOverwrite,
      moduleUid: uid,
      applicationUid: application.uid,
      isOn: r.isOn
    });
  };

  if (selected.isOn) {
    return (
      <>
        <ModuleDisableSwitch
          record={selected}
          resource={applicationResource}
          resourceId={resourceId}
          onChange={handleOnSwitch}
          readOnly={readOnly}
        />
        <OverwritePermissionCheckbox
          record={selected}
          resource={applicationResource}
          resourceId={resourceId}
          onChange={handleOnSwitch}
          readOnly={readOnly}
        />
      </>
    );
  }
  return (
    <ModuleEnableSwitch
      record={selected}
      resource={applicationResource}
      resourceId={resourceId}
      onChange={handleOnSwitch}
      readOnly={readOnly}
    />
  );
};

export default ModuleAssignmentSwitch;
