import React from "react";
import keyBy from "lodash/keyBy";
import { makeStyles } from "@material-ui/core";

import ApplicationAssignmentSwitch from "../common/ApplicationAssignmentSwitch";

import { TextField, Loading, useQuery, Datagrid, Pagination } from "react-admin";

const useStyles = makeStyles({
  enabledCell: {
    maxWidth: "275px",
    width: "275px"
  }
});

type ApplicationGridProps = {
  resourceId: string,
  applicationAssignments: any[],
  name: string,
  onSwitched?: Function,
  resource?: string,
  readOnly: boolean,
};

const ApplicationGrid = (props: ApplicationGridProps) => {
  const { resourceId, applicationAssignments, name, onSwitched, resource, readOnly } = props;
  const classes = useStyles();
  const [page, setPage] = React.useState(1);
  const perPage = 10;
  const { data, total, loading, error } = useQuery({
    type: "getList",
    resource: "applications",
    payload: {
      pagination: { page, perPage },
      sort: { field: "name", order: "asc" },
      filter: { simple: true }
    }
  });
  if (loading) {
    return <Loading />;
  }
  if (error) {
    return <p>ERROR</p>;
  }

  const newData = data.map((d: any) => {
    const applicationAssignment = (applicationAssignments || []).find((a: any) => a.applicationUid === d.uid);
    const applicationAssignmentUid = applicationAssignment ? applicationAssignment.uid : undefined;
    return {
      ...d,
      id: d.uid,
      applicationUid: d.uid,
      applicationAssignmentUid,
      resourceId
    };
  });

  return (
    <>
      <Datagrid
        data={keyBy(newData, "id")}
        ids={newData.map(({ id }: any) => id)}
        currentSort={{ field: "name", order: "ASC" }}
      >
        <TextField source="name" sortable={false} />
        <TextField source="key" sortable={false} />
        <ApplicationAssignmentSwitch
          label="Enabled"
          sortable={false}
          applicationResource={resource}
          headerClassName={classes.enabledCell}
          cellClassName={classes.enabledCell}
          resourceId={resourceId}
          onSwitched={onSwitched}
          name={name}
          readOnly={readOnly}
        />
      </Datagrid>
      <Pagination page={page} perPage={perPage} setPage={setPage} total={total} rowsPerPageOptions={[]} />
    </>
  );
};

const getFilter = (record: any, resource: string) => {
  switch (resource) {
    case "organizations":
      return { organizationUid: record.id };
    case "users":
      return { userUid: record.id };
    default:
      return {};
  }
};

const dataMapper = (d: any, resource = "") => {
  switch (resource) {
    case "users":
    case "organizations":
      return {
        uid: d.uid,
        applicationUid: d.application.uid
      };
    default:
      return d;
  }
};

const getNameFromRecord = (resource = "", record?: any) => {
  switch (resource) {
    case "users":
      return record?.userName;
    case "organizations":
      return record?.name;
    default:
      return "";
  }
};

type ApplicationManagerProps = {
  record: any,
  resource: string,
  readOnly: boolean,
};

const ApplicationManager = (props: Partial<ApplicationManagerProps>) => {
  const { record, resource, readOnly } = props;
  const { data, loading, error } = useQuery({
    type: "getList",
    resource: `${resource}-applications`,
    payload: {
      pagination: { page: 1, perPage: 100 },
      sort: { field: "name", order: "asc" },
      filter: getFilter(record, resource ? resource : "")
    }
  });
  if (loading) {
    return <Loading />;
  }
  if (error) {
    console.log(error);
    return <p>ERROR</p>;
  }
  const applicationAssignments = data.map((d: any) => dataMapper(d, resource));

  return (
    <ApplicationGrid
      {...props}
      applicationAssignments={applicationAssignments}
      resourceId={record.id}
      name={getNameFromRecord(resource, record)}
      readOnly={readOnly ? true : false}
    />
  );
};

export default ApplicationManager;
