import React from "react";
import {
  List,
  Datagrid,
  TextField,
  Filter,
  TextInput,
  SelectInput,
  useAuthenticated
} from "react-admin";
import OrgTypeSelect from "./OrgTypeSelect";

const OrganizationFilter = (props: any) => (
  <Filter {...props}>
    <TextInput source="name" alwaysOn />
    <SelectInput source="status" choices={[
      { id: 'Active', name: 'Active' },
      { id: 'Inactive', name: 'Inactive' },
      { id: 'Pending', name: 'Pending' },
    ]} alwaysOn resettable={true} />
    <OrgTypeSelect label="Group" source="type" alwaysOn resettable={true}/>
  </Filter>
);

const OrganizationList = (props: any) => {
  useAuthenticated();
  const readOnly = true;
  const showOrEdit = readOnly ? 'show' : 'edit';
  return (
    <List
      {...props}
      perPage={25}
      filters={<OrganizationFilter />}
      filter={{ simple: false }}
      exporter={false}
      bulkActionButtons={false}
      sort={{ field: "name", order: "asc" }}
    >
      <Datagrid rowClick={showOrEdit}>
        <TextField source="name" />
        <TextField source="actCode" />
        <TextField source="status" />
        <TextField source="type" />
        <TextField label="Group" source="group.name" />
      </Datagrid>
    </List>
  );
};

export default OrganizationList;
