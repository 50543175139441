import React from "react";
import {
  SelectInput,
} from "react-admin";

const UserStatusSelect = (props: any) => {

  const choices = [
    { id: 'Active', name: 'Active' },
    { id: 'Inactive', name: 'Inactive' },
    { id: 'Pending', name: 'Pending' },
    { id: 'Disabled', name: 'Disabled' },
  ];

  return (
    <SelectInput {...props} choices={choices} defaultValue="" />
  );
};

export default UserStatusSelect;
