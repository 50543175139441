import ModuleList from "./ModuleList";
import ModuleEdit from "./ModuleEdit";
import ModuleShow from "./ModuleShow";
import ModuleCreate from "./ModuleCreate";
import Widgets from "@material-ui/icons/Widgets";

export default {
  list: ModuleList,
  edit: ModuleEdit,
  show: ModuleShow,
  create: ModuleCreate,
  icon: Widgets
};
