import React, { forwardRef } from "react";
import keyBy from "lodash/keyBy";
import { useDataProvider, Loading, useQuery, Datagrid, TextField } from "react-admin";
import MaterialTable from "material-table";

import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import globalStyles from "../common/globalStyles";

type KeyValManagerProps = {
  keyLabel: string,
  resource?: string,
  record?: any,
  collection: string,
  readOnly: boolean,
};

const KeyDatagrid = (props: any) => {
  const {
    resource,
    collection,
    record: { uid }
  } = props;
  const styles = globalStyles()

  const { data, loading, error } = useQuery({
    type: "getList",
    resource: `${resource}/${uid}/${collection}`,
    payload: {},
  });
  if (loading) {
    return <Loading />;
  }
  if (error) {
    return <p>ERROR</p>;
  }

  const newData = data.map((d: any) => {
    return {
      ...d,
      id: d.key
    };
  });

  return (
      <Datagrid
        className={styles.dataGrid}
        data={keyBy(newData, "id")}
        ids={newData.map(({ id }: any) => id)}
        currentSort={{ field: "key", order: "ASC" }}
      >
        <TextField source="key" sortable={false} />
      </Datagrid>
  )
};

const KeyValManager = (props: KeyValManagerProps) => {

  const {
    keyLabel,
    resource,
    record: { uid },
    collection,
    readOnly,
  } = props;

  const dataProvider = useDataProvider();

  return readOnly ? <KeyDatagrid {...props} /> : (
    <MaterialTable
      style={{ marginBottom: "40px" }}
      components={{
        Container: props => <div {...props} />
      }}
      icons={{
        Add: forwardRef((props, ref) => <AddIcon {...props} ref={ref} color="primary" />),
        Edit: forwardRef((props, ref) => <EditIcon {...props} ref={ref} color="primary" />),
        Delete: forwardRef((props, ref) => <DeleteIcon {...props} ref={ref} color="primary" />),
        Check: forwardRef((props, ref) => <CheckIcon {...props} ref={ref} color="primary" />),
        Clear: forwardRef((props, ref) => <ClearIcon {...props} ref={ref} color="primary" />)
      }}
      title=""
      columns={[
        { title: keyLabel, field: "key" },
      ]}
      options={{
        filtering: false,
        exportButton: false,
        search: false,
        actionsColumnIndex: -1,
        paging: false,
        sorting: false,
        draggable: false
      }}
      data={(_: any) => {
        return new Promise((resolve, _) => {
          let pagination: any = {
            page: 1,
            perPage: 25
          };
          dataProvider.getMany(`${resource}/${uid}/${collection}`, { pagination }).then((r: any) => {
            let d = [];
            if (r) {
              d = (r.data || []).map((a: any) => ({
                ...a,
                value: a.value
              }));
            }

            resolve({
              data: d,
              page: 0,
              totalCount: d.length
            });
          });
        });
      }}
      editable={{
        isEditable: () => false,
        onRowAdd: newData => {
          return dataProvider.update(`${resource}/${uid}`, {
            data: {
              key: newData.key
            },
            id: collection,
          });
        },
        onRowDelete: oldData =>
          dataProvider.delete(`${resource}/${uid}`, {
            id: collection,
            body: {
              key: oldData.key,
            }
          })
      }}
    />
  );
};

export default KeyValManager;
