import React from "react";
import { useNotify, useMutation } from "react-admin";
import Switch from "@material-ui/core/Switch";
import { DatagridColumn } from "../types";

type PermissionsSwitchFieldProps = DatagridColumn & {
  record?: any;
  disabled?: boolean;
};

const PermissionSwitchField = (props: PermissionsSwitchFieldProps) => {
  const { disabled, record } = props;
  const notify = useNotify();
  const [selected, setSelected] = React.useState(record.defaultPermission);
  const [update, { loading }] = useMutation(
    {
      type: "update",
      resource: "modules",
      payload: { id: record.id, data: { defaultPermission: !selected } }
    },
    {
      onSuccess: (response: any) => {
        const { data } = response;
        setSelected(data.module.defaultPermission);
      },
      onFailure: (err: any) => {
        console.error(err);
        notify(`Module not updated`, "warning");
      }
    }
  );

  return (
    <Switch
      color="primary"
      key={record.id}
      checked={selected}
      onChange={update}
      disabled={disabled || loading}
      inputProps={{ "aria-label": "default permission" }}
    />
  );
};

export default PermissionSwitchField;
