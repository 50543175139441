import React from "react";
import keyBy from "lodash/keyBy";
import { makeStyles } from "@material-ui/core";

import ModuleAssignmentSwitch from "../common/ModuleAssignmentSwitch";

import { TextField, Loading, useQuery, Datagrid, Pagination } from "react-admin";

const useStyles = makeStyles({
  enabledCell: {
    maxWidth: "275px",
    width: "275px"
  }
});

type ModuleGridProps = {
  applicationUid: string,
  applicationModules: ApplicationModule[],
  resource: string,
  resourceId: string,
  readOnly: boolean,
}

const ModuleGrid = ({ applicationUid, applicationModules, resource, resourceId, readOnly }: ModuleGridProps) => {
  const classes = useStyles();
  const [page, setPage] = React.useState(1);
  const perPage = 10;
  const { data, total, loading, error } = useQuery({
    type: "getList",
    resource: "modules",
    payload: {
      pagination: { page, perPage },
      sort: { field: "name", order: "asc" },
      filter: { applicationUid: applicationUid }
    }
  });
  if (loading) {
    return <Loading />;
  }
  if (error) {
    return <p>ERROR</p>;
  }

  const newData = data.map((d: any) => {
    const applicationModule = (applicationModules || []).find((a: ApplicationModule) => a.moduleUid === d.uid);
    return {
      ...d,
      applicationModule,
      isOn: applicationModule ? true : false
    };
  });

  return (
    <>
      <Datagrid
        data={keyBy(newData, "id")}
        ids={newData.map(({ id }: any) => id)}
        currentSort={{ field: "name", order: "ASC" }}
      >
        <TextField source="name" sortable={false} />
        <TextField source="key" sortable={false} />
        <ModuleAssignmentSwitch
          label="Enabled"
          sortable={false}
          applicationResource={resource}
          resourceId={resourceId}
          headerClassName={classes.enabledCell}
          cellClassName={classes.enabledCell}
          readOnly={readOnly}
        />
      </Datagrid>
      <Pagination page={page} perPage={perPage} setPage={setPage} total={total} rowsPerPageOptions={[]} />
    </>
  );
};

type ModuleManagerProps = {
  record?: any,
  applicationResource: string,
  readOnly: boolean,
};

type ApplicationModule = {
  moduleUid: string,
  permissionOverwrite: string,
  resourceUid: string,
};

const ModuleManager = (props: ModuleManagerProps) => {
  const { record, applicationResource, readOnly } = props;
  const { uid, applicationUid } = record;

  const { data, loading, error } = useQuery({
    type: "getList",
    resource: `${applicationResource}/${uid}/modules`,
    payload: {
      pagination: { page: 1, perPage: 100 },
      sort: { field: "name", order: "asc" }
    }
  });
  if (loading) {
    return <Loading />;
  }
  if (error) {
    return <p>ERROR</p>;
  }
  const applicationModules: ApplicationModule[] = data.map((d: any) => {
    let mapped = {
      moduleUid: d.module.uid,
      permissionOverwrite: d.permissionOverwrite
    };

    switch (applicationResource) {
      case "organizations-applications":
        return { ...mapped, resourceUid: d.organizationApplicationUid };
      case "users-applications":
        return { ...mapped, resourceUid: d.userApplicationUid };
      default:
        return mapped;
    }
  });

  return (
    <ModuleGrid
      resource={applicationResource}
      resourceId={uid}
      applicationModules={applicationModules}
      applicationUid={applicationUid}
      readOnly={readOnly}
    />
  );
};

export default ModuleManager;
