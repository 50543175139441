import React from "react";
import { Permissions } from "../types";
import { useHistory } from "react-router-dom";
import { useQuery } from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles(theme => ({
  error: {
    color: theme.palette.error.main
  },
  root: {
    "padding-top": theme.spacing(4),
    display: "flex",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(1),
      "flex-grow": 1,
      "flex-basis": 0,
      padding: theme.spacing(5),
      "& > h1": {
        height: 45 // to fit the CircularProgress
      },
      textAlign: "center",
      "&:hover": {
        color: theme.palette.primary.light,
        cursor: "pointer"
      }
    }
  }
}));

const Counter = (props: any) => {
  const { resource, label } = props;

  const classes = useStyles();
  const history = useHistory();

  const handleClick = (_: React.MouseEvent<any>) => {
    history.push(`/${resource}`);
  };

  const { total, loading, error } = useQuery({
    type: "getList",
    resource: resource,
    payload: {
      pagination: { page: 1, perPage: 1 },
      sort: { field: "", order: "" }
    }
  });

  return (
    <Paper onClick={handleClick}>
      {error ? <h1 className={classes.error}>ERR!</h1> : null}
      {loading ? (
        <h1>
          <CircularProgress />
        </h1>
      ) : (
        <h1>{total}</h1>
      )}
      <span>{label}</span>
    </Paper>
  );
};

export default (props: any) => {
  const { permissions } = props;
  const p = permissions as Permissions;
  const classes = useStyles();
  return (
    <div className={classes.root}>
      {(p && p.readOrganization) ?
       <Counter resource="organizations" label="Organizations" /> : null
      }
      <Counter resource="users" label="Users" />
      <Counter resource="applications" label="Applications" />
      <Counter resource="modules" label="Modules" />
    </div>
  );
};
