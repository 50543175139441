const BASE_URL = process.env.REACT_APP_API_URL;
const API_KEY = process.env.REACT_APP_API_KEY || "NO-KEY";
const JSON_HEADER_OBJ = {
  "Content-Type": "application/json",
  "X-API-KEY": API_KEY,
  accept: "application/json"
}
const JSON_HEADERS = new Headers(JSON_HEADER_OBJ);

export { BASE_URL, API_KEY, JSON_HEADER_OBJ, JSON_HEADERS };
