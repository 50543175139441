import React from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  Toolbar,
  SaveButton,
  SelectInput,
  required,
  useAuthenticated,
  ReferenceInput,
} from "react-admin";
import globalStyles from "../common/globalStyles";

const EditToolbar = (props: any) => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
);

const OrganizationCreate = (props: any) => {
  const styles = globalStyles();
  useAuthenticated();
  return (
    <Create {...props} successMessage="Organization Saved">
      <SimpleForm toolbar={<EditToolbar />} className={styles.simpleForm}>
        <TextInput source="name" validate={[required()]} className={styles.input} />
        <SelectInput
          source="type"
          defaultValue="Organization"
          choices={[
            { id: "Organization", name: "Organization" },
            { id: "OrgType", name: "OrgType" }
          ]}
          className={styles.input}
        />

        <SelectInput
          source="status"
          defaultValue="Active"
          choices={[
            { id: "Active", name: "Active" },
            { id: "Inactive", name: "Inactive" },
            { id: "Pending", name: "Pending" }
          ]}
          className={styles.input}
        />

        <TextInput label="External ID" source="externalId" className={styles.input}/>
        <TextInput label="SF Account" source="sfAccountId" className={styles.input}/>
        <TextInput label="ACT Code" source="actCode" className={styles.input}/>
        <ReferenceInput
          source="group.uid"
          reference="organizations"
          filter={{ simple: true, orgType: 'OrgType' }}
          sort={{ field: "name", order: "ASC" }}
          perPage={5}
          label="Org Type"
          className={styles.input}
        >
          <SelectInput optionText="name" />
        </ReferenceInput>
      </SimpleForm>
    </Create>
  );
};

export default OrganizationCreate;
