import { makeStyles } from '@material-ui/core/styles';

const globalStyles = makeStyles({
  simpleForm: {
    width: 550
  },
  input: {
    width: 550
  },
  dataGrid: {
    marginBottom: 20
  }
});

export default globalStyles;
