import React from "react";
import {
  List,
  Datagrid,
  TextField,
  Filter,
  TextInput,
  useAuthenticated,
  UrlField,
} from "react-admin";

const ApplicationFilter = (props: any) => (
  <Filter {...props}>
    <TextInput source="name" alwaysOn />
  </Filter>
);

export const ApplicationList = (props: any) => {
  useAuthenticated();
  const readOnly = props?.permissions?.readOnly && true;
  const showOrEdit = readOnly ? 'show' : 'edit';
  return (
    <List
      {...props}
      filters={<ApplicationFilter />}
      perPage={25}
      exporter={false}
      bulkActionButtons={false}
      sort={{ field: "name", order: "asc" }}
      filter={{ simple: true }}
    >
      <Datagrid rowClick={showOrEdit}>
        <TextField source="name" />
        <UrlField source="url" />
      </Datagrid>
    </List>
  );
};

export default ApplicationList;
