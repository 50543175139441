import React from "react";
import { MenuItemLink, DashboardMenuItem } from "react-admin";
import { useSelector } from "react-redux";
import { ReduxState } from "ra-core";
import classnames from 'classnames';
import authProvider from "../providers/authProvider";
import { makeStyles } from '@material-ui/core/styles';
import UserIcon from "@material-ui/icons/People";
import WebAssetIcon from "@material-ui/icons/WebAsset";
import SchoolIcon from "@material-ui/icons/School";
import WidgetsIcon from "@material-ui/icons/Widgets";

const useStyles = makeStyles(
  theme => ({
    root: {
      color: theme.palette.text.secondary,
    },
    active: {
      color: theme.palette.text.primary,
    },
    icon: { minWidth: theme.spacing(5) },
  }),
  { name: 'UmsMenuLink' }
);

export default (props: any) => {
  const { className, dense, hasDashboard, onMenuClick } = props;
  const open = useSelector((state: ReduxState) => state.admin.ui.sidebarOpen);
  const permissions = authProvider.getPermissionsNow();
  const classes = useStyles(props);

  // Force Redraw
  useSelector((state: ReduxState) => state.router.location.pathname);

  return permissions ? (
    <div>
      {hasDashboard && <DashboardMenuItem onClick={onMenuClick} dense={dense} sidebarIsOpen={open} />}
      <MenuItemLink
        className={classnames(classes.root, className)}
        activeClassName={classes.active}
        to="/users"
        primaryText="Users"
        onClick={onMenuClick}
        leftIcon={<UserIcon />}
        sidebarIsOpen={open}
      />
      <MenuItemLink
        to="/applications"
        primaryText="Applications"
        onClick={onMenuClick}
        leftIcon={<WebAssetIcon />}
        sidebarIsOpen={open}
      />
      {permissions.readOrganization ? (
        <MenuItemLink
          to="/organizations"
          leftIcon={<SchoolIcon />}
          primaryText="Organizations"
          onClick={onMenuClick}
          sidebarIsOpen={open}
        />
      ) : null}
      <MenuItemLink
        to="/modules"
        leftIcon={<WidgetsIcon />}
        primaryText="Modules"
        onClick={onMenuClick}
        sidebarIsOpen={open}
      />
    </div>
  ) : null;
};
