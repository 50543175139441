import isUrl from "validator/lib/isURL";

// Using the same url validation that Sequelize does.
export const validateUrl = (value: string, _: any) => {
  if (!value) {
    return "A url is required";
  }
  if (!isUrl(value)) {
    return "Must be a valid URL";
  }
  return undefined;
};
