import OrganizationList from "./List";
import OrganizationEdit from "./Edit";
import OrganizationShow from "./Show";
import OrganizationCreate from "./Create";
import School from "@material-ui/icons/School";

export default {
  list: OrganizationList,
  edit: OrganizationEdit,
  show: OrganizationShow,
  create: OrganizationCreate,
  icon: School
};
