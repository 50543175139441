import React from "react";
import {
  TextInput,
  useAuthenticated,
  Show,
  Datagrid,
  EditButton,
  ShowButton,
  TabbedForm,
  FormTab,
  TextField,
  Pagination,
  ReferenceManyField,
  TopToolbar,
  ListButton,
  required
} from "react-admin";

import globalStyles from "../common/globalStyles";
import AttributeGrid from "../common/AttributeGrid";
import ModulePermissionSwitch from "../common/ModulePermissionSwitch";
import { validateUrl } from "../common/validators";

const EditActions = (props: any) => {
  const { basePath, data } = props;
  return (
    <TopToolbar>
      <ListButton basePath={basePath} record={data} />
    </TopToolbar>
  );
};

const EditTitle = (props: any) => {
  const { record } = props;
  return <span>{record ? `${record.name}` : ""}</span>;
};

const ApplicationShow = (props: any) => {
  const styles = globalStyles();
  const readOnly = props?.permissions?.readOnly && true;
  useAuthenticated();
  return (
    <div>
      <Show {...props} title={<EditTitle />} actions={<EditActions />}>
        <TabbedForm toolbar={null}>
          <FormTab label="Summary">
            <TextInput disabled source="name" validate={[required()]} className={styles.input} />
            <TextInput
              disabled
              source="key"
              parse={(v: string) => v.toLowerCase()}
              format={(v: string) => v.toLowerCase()}
              validate={[required()]}
              className={styles.input}
            />
            <TextInput
              disabled
              source="url"
              helperText="http(s)://yourdomain.com"
              validate={[required(), validateUrl]}
              className={styles.input}
            />
            <TextInput
              disabled
              label="Admin URL"
              helperText="http(s)://yourdomain.com"
              source="adminUrl"
              validate={[required(), validateUrl]}
              className={styles.input}
            />
          </FormTab>
          <FormTab label="Modules">
            <ReferenceManyField
              pagination={<Pagination />}
              fullWidth
              reference="modules"
              target="applicationUid"
              sort={{ field: "name", order: "ASC" }}
              addLabel={false}
            >
              <Datagrid>
                <TextField source="name" />
                <TextField source="key" />
                <ModulePermissionSwitch disabled={true} source="defaultPermission" label="Default Permission" textAlign="center" />
                {readOnly ? (<ShowButton/>) : (<EditButton />)}
              </Datagrid>
            </ReferenceManyField>
          </FormTab>
          <FormTab label="Attributes">
            <AttributeGrid readOnly={true} {...props} />
          </FormTab>
        </TabbedForm>
      </Show>
      <br />
    </div>
  );
};

export default ApplicationShow;
